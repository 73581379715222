import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/business/LoginView.vue'
import AgreeView from '../views/business/AgreeView.vue'
import TerminalStatusView from '../views/business/TerminalStatusView.vue'
import AdminPasswordView from '../views/business/AdminPasswordView.vue'
import AppSettingsView from '../views/business/AppSettingsView.vue'
import EntryExitLogsView from '../views/business/EntryExitLogsView.vue'
import UserRegistrationView from '../views/consumer/UserRegistrationView.vue'
import UserPasswordView from '../views/consumer/UserPasswordView.vue'
import UserPasswordResetView from '../views/consumer/UserPasswordResetView.vue'
import UserPasswordSetView from '../views/consumer/UserPasswordSetView.vue'
import requestView from '../views/business/RequestView.vue'
import EditCorporateView from '../views/administrator/EditCorporateView.vue'

// 画面遷移前にログイン済みかを判定するメソッドに必要なFirebaseのメソッド
import { getAuth } from "firebase/auth";
import { ref as databaseRef, onValue, query } from "firebase/database";
import Firebase from "../firebase_settings/index.js"

const database = Firebase.database;
const auth = getAuth()

const routes = [
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: { title: 'ログイン', requiresAuth: false, agreeAuth: false}
    },
    {
        path: '/agree',
        name: 'agree',
        component: AgreeView,
        meta: { title: '規約同意', requiresAuth: true, agreeAuth: false}
    },
    {
        path: '/',
        name: 'terminalstatus',
        component: TerminalStatusView,
        meta: { title: '端末状況', requiresAuth: true, agreeAuth: true}
    },
    {
        path: '/adminpassword',
        name: 'adminpassword',
        component: AdminPasswordView,
        meta: { title: '管理パスワード変更', requiresAuth: true, agreeAuth: true}
    },
    {
        path: '/appsettings',
        name: 'appsettings',
        component: AppSettingsView,
        meta: { title: 'アプリ設定', requiresAuth: true, agreeAuth: true}
    },
    {
        path: '/entryexitlogs',
        name: 'entryexitlogs',
        component: EntryExitLogsView,
        meta: { title: '入退室ログ', requiresAuth: true, agreeAuth: true}
    },
    {
        path: '/userregistration',
        name: 'userregistration',
        component: UserRegistrationView,
        meta: { title: '利用者登録', requiresAuth: true, agreeAuth: true}
    },
    {
        path: '/request',
        name: 'request',
        component: requestView,
        meta: { title: 'プラン変更・退会申請', requiresAuth: true, agreeAuth: true}
    },
    {
        path: '/editcorporate',
        name: 'editcorporate',
        component: EditCorporateView,
        meta: { title: '企業の追加・削除', requiresAuth: true, agreeAuth: true, debugAuth: true}
    },
    {
        path: '/userpassword/',
        name: 'userpassword',
        component: UserPasswordView,
        meta: { title: '利用者のパスワード変更'}
    },
    {
        path: '/userpasswordreset/',
        name: 'userpasswordreset',
        component: UserPasswordResetView,
        meta: { title: '利用者のパスワードリセット'}
    },
    {
        path: '/userpasswordset/',
        name: 'userpasswordset',
        component: UserPasswordSetView,
        meta: { title: '利用者のパスワード再設定'}
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// 画面遷移成功後にページタイトルを設定
router.afterEach((titleString) => {
    document.title = titleString.meta.title + ' | ProoFace'
});

function isAuthenticated() {
    return new Promise((resolve) => {
        const unsubscribe = getAuth().onAuthStateChanged((user) => {
            unsubscribe();
            resolve(user ? true : false);
        })
    })
}

function checkAgreeFlag() {
    const getDataRef = databaseRef(database, `LoginUser/${auth.currentUser.uid}/Agree`);
    return new Promise((resolve) => {
        onValue(getDataRef, (snapshot) => {
            resolve(snapshot.val() ? true : false);
        })
    });
}

function checkDebugFlag() {
    const getDataRef = databaseRef(database, `LoginUser/${auth.currentUser.uid}/EditAuthority`);
    return new Promise((resolve) => {
        onValue(getDataRef, (snapshot) => {
            resolve(snapshot.val() ? true : false);
        })
    });
}

const getService = async (userCredential) => {
    const getDataRef = databaseRef(database, `LoginUser/${auth.currentUser.uid}/CorporateId`);
    const CorporateId = await new Promise((resolve) => {
        const unsubscribe = onValue(getDataRef, (snapshot) => {
            unsubscribe();
            resolve(snapshot.val());
        });
    });
    const getCorporateDataRef = databaseRef(database, `CorporateId/${CorporateId}/CorporateId`);
}

// 画面遷移前にログイン済みかをチェックして、未ログイン時はログイン画面に強制遷移させる
router.beforeEach(async(to, from) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const agreeAuth = to.matched.some((record) => record.meta.agreeAuth);
    const debugAuth = to.matched.some((record) => record.meta.debugAuth);
    //const requireService = await getService()
    if(agreeAuth && await isAuthenticated() && !(await checkAgreeFlag())) {
        return '/agree'
    }
    else if(requiresAuth && !(await isAuthenticated())) {
        return '/login'
    }
    else if ((to.fullPath == '/editcorporate') && debugAuth && !(await checkDebugFlag())) {
        return '/'
    }
})
export default router
