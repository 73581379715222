<script setup>
    import { reactive, watch } from 'vue'
    import { useRoute, useRouter, parseQuery } from 'vue-router'
    import axios from 'axios';
    import Error from "../../firebase_settings/error.js"
    import HeaderComponent from '../../components/common/HeaderComponent.vue'
    import Firebase from "../../firebase_settings/index.js"

    const auth = Firebase.auth
    const route = useRoute()
    const router = useRouter()
    //入力とローディング用のリアクティブオブジェクト
    const reactiveObj = reactive({
        //firebaseUUID
        uuid: "",
        //ローディングアニメーション
        loadingFlag: false
    })
    //次に遷移するパスを取得する
    const getLink = () => {
        //同期処理
        return new Promise((resolve, reject) => {
            //APIにてパスワードリセット関数を実行
			axios({
                baseURL: process.env.VUE_APP_USERPASSWORDRESET,
                method: 'post',
                params:{
                    uuid: reactiveObj.uuid,
                    corporateId: route.query.CorporateId
                }
            })
            //成功時
            .then((res) => {
                //パスワードリセット画面へ遷移する際の情報を返す
                resolve({
                    name: 'userpasswordset',
                    query: parseQuery(res.data)
                })
            })
            //エラー時
            .catch((error) => {
                reject(error.response.data)
            })
        })
    }
    //次へボタンを押下した際に実行
    const checkUUid = async () => {
        //ローディング開始
        reactiveObj.loadingFlag = true
        try {
            //getLink()で帰ってきたパスへ遷移させる
            router.push(await getLink())
        }
        //エラー時
        catch(error) {
            alert(Error.errorResult(error, route.name))
        }
        //ローディング終了
        finally {
            reactiveObj.loadingFlag = false
        }
    }
    //リアクティブオブジェクトの監視
    watch(reactiveObj, () => {
        //uuidが入力されたら次へボタンを活性化
        if(reactiveObj.uuid){
            document.getElementById('commonButton').disabled = false;
        }
    })
</script>

<template>
    <HeaderComponent></HeaderComponent>
    <main>
        <link rel="stylesheet" href="./togglePassword.css">
        <link href="https://use.fontawesome.com/releases/v5.6.1/css/all.css" rel="stylesheet">
        <div id="loadingDisplay" class="loading" v-show="reactiveObj.loadingFlag">
            <div class="load-text load-blink">処理中...</div>
        </div>
        <div class="userPassword">
            <div class="titleArea">
                <p class="title">利用者のパスワードリセット</p>
            </div>
            <table class="commonTable">
                <tbody>
                    <tr>
                        <th></th>
                    </tr>
                    <tr>
                        <td class="firstTd notButtom">　利用者識別コード（UID）</td>
                    </tr>
                    <tr>
                        <td class="firstTd notTop">
                            <input type="checkbox" class="checkPassword">
                            <div class="togglePassword">
                                <input type="password" class="hideText" v-model="reactiveObj.uuid">
                                <!--<input type="text" class="showText" v-model="reactiveObj.uuid">-->
                                <!--<label for="checkPassword" class="fa fa-eye"></label>-->
                                <!--<label for="checkPassword" class="fa fa-eye-slash"></label>-->
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="updateArea">
                            <button type="button" id="commonButton" @click="checkUUid()" disabled="true">次へ</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </main>
</template>

<style scoped>
    .userPassword {
        padding: 0 20% 0 20%;
        background-color: #e7e6e6;
    }
    .commonTable tr:first-child>*:last-child {
        border-radius: 20px 20px 0 0;
    }
    .loadingAnimationContainer {
        height: 15px;
        margin-top: 5%;
    }
    .commonTable td {
        height: 75px;
    }
    .commonTable .updateArea {
        height: 150px;
    }
    @media (max-width: 1150px) {
        .userPassword {
            padding: 0 5% 0 5%;
        }
        #commonButton {
            width: 30vw;
            left: 17%;
        }
    }
</style>
