
<script setup>
    import { computed, reactive, onMounted } from 'vue'
    import { onAuthStateChanged, signOut } from "firebase/auth";
    import { ref as databaseRef, onValue, update, push, child, set } from "firebase/database";
    import { useRoute } from 'vue-router'
    import HeaderComponent from "../../components/common/HeaderComponent.vue"
    import AddCorporateComponent from "../../components/administrator/AddCorporateComponent.vue"
    import DefaultModalComponent from '../../components/common/DefaultModalComponent.vue'
    import PaginateComponent from 'vuejs-paginate-next'
    import Firebase from "../../firebase_settings/index.js"
    import Error from "../../firebase_settings/error.js"
    import axios from 'axios';

    const auth = Firebase.auth
    const database = Firebase.database;
    const route = useRoute()

    //企業情報等のリアクティブオブジェクト
    const reactiveObj = reactive({
        //企業ID
        corporateObjects: [],
        //
        renderKey: 0,
        //
        addModalFlag: false,
        //
        currentPage: 1,
        //
        modalFlag: false,
        //
        modalMessage: "",
        //
        modalRedMessage: "",
        //
        loadingFlag: false,
        //
        deleteFlag: false,
        //
        otherObject: {}
    })
    //1ページに表示する件数
    const perPage = 10;
    //firebaseからログイン利用者の情報を取得
    const getCorporate = () => {
        //ログイン情報をチェック
        onAuthStateChanged(auth, (user) => {
            if (user) {
                //DBの取得したいパスを指定
                const getData = databaseRef(database, `LoginUser/`)
                //DBを取得
                onValue(getData, (snapshot) => {
                    reactiveObj.corporateObjects = []
                    const corporateList = (snapshot.val())
                    for (let key in corporateList) {
                        reactiveObj.corporateObjects.push({
                            'Id': key,
                            'Administorator': corporateList[key]['Administorator'],
                            'Agree': corporateList[key]['Agree'],
                            'Email': corporateList[key]['Email'],
                            'CorporateId': corporateList[key]['CorporateId'],
                            'CorporateName': corporateList[key]['CorporateName'],
                            'EditAuthority': corporateList[key]['EditAuthority']
                        })
                    }
                    //LatestHistory順に並べ替え
                    reactiveObj.corporateObjects = reactiveObj.corporateObjects.sort((element, nextElement) => {
                        return (element['CorporateId'] < nextElement['CorporateId']) ? -1 : 1;
                    })
                    reactiveObj.renderKey++
                })
            } else {
            }
        })
    }
    //APIの実行
    const execFunction = (API, params) => {
        return new Promise(async (resolve, reject) => {
            axios({
                baseURL: API,
                method: 'post',
                params: params
            })
            .then((res) => {
                reactiveObj.loadingFlag = false
                resolve()
            })
            .catch(async (error) => {
                reactiveObj.loadingFlag = false
                reject(Error.errorResult(error.response.data, route.name))
            })
        })
    }
    //企業追加のモーダルウィンドウを開く際に実行
    const showAddModal = () => {
        //モーダルウィンドウを開く
        reactiveObj.otherObject = {
            //企業コードの一覧を重複なしで抽出
            corporateIdList : Array.from(new Set(reactiveObj.corporateObjects.map(element => element.CorporateId)))
        }
        reactiveObj.addModalFlag = true
        reactiveObj.renderKey++
    }
    //企業追加のモーダルウィンドウを閉じる際に実行
    const closeAddModal = (editFlag, newCorporateObj) => {
        //モーダルウィンドウを閉じる
        reactiveObj.addModalFlag= false;
        //変更ボタンを押下した時
        if(editFlag) {
            reactiveObj.loadingFlag = true;
            execFunction(
                process.env.VUE_APP_REGISTERADMIN,
                {
                    corporateName: newCorporateObj.corporateName,
                    userName: newCorporateObj.userName,
                    corporateId: newCorporateObj.corporateId,
                    adminEmail: newCorporateObj.adminEmail,
                }
            )
            .then(() => showDefaultModal('登録処理が完了しました'))
            .catch((errorMessage) => alert(errorMessage))
        };
        reactiveObj.otherObject = {};
    }
    //企業削除時に必要な情報をセットする
    const deleteDataSet = (data, key) => {
        //子コンポーネントに渡すオブジェクト
        reactiveObj.otherObject = {
            adminIndex: key,
            corporateId: data.CorporateId,
            confirm: '削除する',
            modalWidth: "35%",
        };
        const message = `以下の企業を削除します。<br>よろしいですか？<br><br>企業名：${data.CorporateName}`;
        reactiveObj.deleteFlag = true
        showDefaultModal(message)
    };
    //モーダルウィンドウを開く際に実行
    const showDefaultModal = (message) => {
        //モーダルウィンドウを開く
        reactiveObj.modalFlag = true;
        //モーダルウィンドウに表示するメッセージを設定　※ここでHTMLの設定しないと反映できない
        reactiveObj.modalMessage = message;
        reactiveObj.modalRedMessage = reactiveObj.deleteFlag ? "※登録されている全ての管理者が削除されます。<br><br>" : "";
        //モーダルウィンドウを再描画
        reactiveObj.renderKey++;
    }
    //モーダルウィンドウを閉じる際に実行
    const closeDefaultModal = async (editFlag) => {
        //モーダルウィンドウを閉じる
        reactiveObj.modalFlag = false;
        //削除に同意しているか
        if(reactiveObj.deleteFlag && editFlag) {
            //削除対象の情報を取得
            const targetEmailList = []
            const corporateId = reactiveObj.otherObject.corporateId;
            const corporateData = databaseRef(database, `CorporateId/${corporateId}`);
            await new Promise((resolve) => {
                const unsubscribe = onValue(corporateData, (snapshot) => {
                    const userData = (snapshot.val().User);
                    for(let key in userData) {
                        targetEmailList.push(userData[key].Email)
                    }
                    unsubscribe(); // リスナー解除
                    resolve()
                })
            });
            reactiveObj.otherObject = {};
            reactiveObj.deleteFlag = false;
            //企業情報を削除
            await set(corporateData, {});
            //企業コードに一致する全管理者を削除
            reactiveObj.corporateObjects.forEach(async (element) => {
                if(element.CorporateId == corporateId) {
                    targetEmailList.push(element.Email)
                    const AdminData = databaseRef(database, `LoginUser/${element.Id}`);
                    await set(AdminData, {});
                };
            });
            reactiveObj.loadingFlag = true;
            execFunction(
                process.env.VUE_APP_DELETEUSER,
                {
                    targetEmailList: [...new Set(targetEmailList)],
                    corporateId: corporateId,
                    force: true
                }
            )
            .then(() => {showDefaultModal('削除処理が完了しました')})
            .catch((errorMessage) => alert(errorMessage))
        };
    }
    //ページネーションを押下した時
    const clickCallback = (pageNum) => {
        reactiveObj.currentPage = Number(pageNum);
    }
    //Nページ目に表示するドア情報を取得
    const getItems = computed(() => {
        let current = reactiveObj.currentPage * perPage;
        let start = current - perPage;
        return reactiveObj.corporateObjects.slice(start, current);
    })
    //現在のページ数を取得
    const getPageCount = computed(() => {
        return Math.ceil(reactiveObj.corporateObjects.length / perPage) ? Math.ceil(reactiveObj.corporateObjects.length / perPage) : 1
    })
    //コンポーネントがマウントされた後に実行
    onMounted(() => {
        //企業一覧を取得する
        getCorporate()
    })
</script>
<template>
    <!-- コメントアウト用 -->
    <HeaderComponent></HeaderComponent>
    <main>
        <div id="loadingDisplay" class="loading" v-show="reactiveObj.loadingFlag">
            <div class="load-text load-blink">処理中...</div>
        </div>
        <div class="corporateList" :key="`${reactiveObj.renderKey}`">
            <div class="titleArea">
                <p class="title">登録企業</p>
                <button id="addButton" @click="showAddModal">企業追加</button>
                <AddCorporateComponent
                    :otherObject="reactiveObj.otherObject"
                    v-show="reactiveObj.addModalFlag"
                    @executeMethod="closeAddModal">
                </AddCorporateComponent>
            </div>
            <div class="tableArea">
                <table class="commonTable">
                    <tbody>
                        <tr>
                            <th id="corporateName">企業名</th>
                            <th id="corporateName">企業コード</th>
                            <th id="adminEmail">管理者</th>
                            <th id="administrator">管理者権限</th>
                            <th id="EditAuthority">編集権限</th>
                            <th id="agree">利用規約</th>
                            <th id="delete">削除</th>
                        </tr>
                        <tr v-for="(data, key) in getItems" :key="key">
                            <td>{{ data.CorporateName }}</td>
                            <td>{{ data.CorporateId }}</td>
                            <td>{{ data.Email }}</td>
                            <td>{{ data.Administorator ? "〇" : "×" }}</td>
                            <td>{{ data.EditAuthority ? "〇" : "×" }}</td>
                            <td>{{ data.Agree ? "〇" : "×" }}</td>
                            <td><button type="button" class="deleteButton" @click="deleteDataSet(data, (reactiveObj.currentPage-1)*perPage+key)"><img src="../../assets/delete.png" id="deletelIcon"></button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <DefaultModalComponent
                :key="reactiveObj.renderKey"
                :modalMessage="reactiveObj.modalMessage"
                :modalRedMessage="reactiveObj.modalRedMessage"
                :otherObject="reactiveObj.otherObject"
                v-show="reactiveObj.modalFlag"
                @executeMethod="closeDefaultModal">
            </DefaultModalComponent>

            <div class="paginationArea">
                <PaginateComponent
                    :page-count="getPageCount"
                    :click-handler="clickCallback"
                    :page-range="5"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :container-class="'pagination'"
                    :page-class="'page-item'">
                </PaginateComponent>
            </div>
        </div>
    </main>
</template>

<style scoped>
    .corporateList {
        padding: 0 20% 0 20%;
        background-color: #e7e6e6;
    }
    #addButton {
        position: relative;
        display: flex;
        margin-left: auto;
        top: -40px;
        border: solid 3px #7CD5F5;
        background: #7CD5F5;
    }
    .commonTable #corporate{
        width: 50%;
    }
    .commonTable #edit{
        width: 10%;
    }
    .commonTable #parrent{
        width: 10%;
    }
    .commonTable #displayLatest{
        width: 20%;
    }
    @media (max-width: 1150px) {
        .corporateList {
            padding: 0 5% 0 5%;
        }
        .tableArea {
            overflow-x: scroll;
        }
    }
</style>
