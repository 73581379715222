<script setup>
    import { reactive } from 'vue'
    import { useRouter, useRoute } from 'vue-router'
    import { signInWithEmailAndPassword, signOut } from "firebase/auth";
    import { ref as databaseRef, onValue } from "firebase/database";
    import Firebase from "../../firebase_settings/index.js"
    import Error from "../../firebase_settings/error.js"

    const auth = Firebase.auth
    const database = Firebase.database;
    const router = useRouter()
    const route = useRoute()
    //ログイン処理に必要な値のリアクティブオブジェクト
    const reactiveObj = reactive({
        // エラーメッセージ
        errorMessage: '',
        // 入力欄
        inputValueId: "",
        inputValuePassword: "",
    })

    // Home画面へ遷移
    const goToHome = () => {
        router.push('/')
    }
    //規約同意画面へ
    const goToAgree = () => {
        router.push('/agree')
    }
    //利用規約同意チェック
    const agreeCheck = (uid) => {
        //同期処理
        return new Promise((resolve) => {
            const getData = databaseRef(database, `LoginUser/${uid}/Agree`)
                onValue(getData, (snapshot) => {
                    resolve(snapshot.val() ? true : false);
                })
        })
    }
    //管理者チェック
    const adminCheck = (uid) => {
        //同期処理
        return new Promise((resolve) => {
            const getData = databaseRef(database, `LoginUser/${uid}/Administorator`)
                onValue(getData, (snapshot) => {
                    resolve(snapshot.val() ? true : false);
                })
        })
    }
    // ログアウト処理
    const logOut = () => {
        signOut(auth)
        .then(() => {
            router.push('/login')
        })
        .catch((error) => {
            alert(Error.errorResult({ code: 'LVE0003'}, route.name))
        });
    }
    // ログイン
    const logIn = () => {
        // IDとパスワードの未入力チェック
        if(reactiveObj.inputValueId === undefined || reactiveObj.inputValueId === "" || reactiveObj.inputValuePassword === undefined || reactiveObj.inputValuePassword === "") {
            reactiveObj.errorMessage = 'IDまたはパスワードが未入力です'
            return
        }
        // エラーメッセージの消去
        reactiveObj.errorMessage = ""
        // id,passを取得
        const mId = reactiveObj.inputValueId
        const mPass = reactiveObj.inputValuePassword
        // 連続クリックを防ぐためにパスワード欄を空欄にする
        reactiveObj.inputValuePassword = ""
        // サインインメソッド
        signInWithEmailAndPassword(auth, mId, mPass)
            // サインイン成功時
            .then(async (userCredential) => {
                //管理者チェック
                if(await adminCheck(userCredential.user.uid)) {
                    //利用規約同意チェック
                    if(await agreeCheck(userCredential.user.uid)) {
                        goToHome()
                    } else {
                        goToAgree()
                    }
                } else {
                    reactiveObj.errorMessage = Error.errorResult({ code: 'LVE0002'}, router.name)
                    logOut()
                }
            })
            // サインイン失敗時
            .catch((error) => {
                reactiveObj.errorMessage = Error.errorResult(error, route.name)
            });
    }
</script>
<template>
    <header>
        <div class="title">ProoFace 管理WEB</div>
    </header>

    <main>
        <div class="loginArea">
            <div class="inputContainer">
                <p>管理者メールアドレス</p>
                <input type="email" v-model="reactiveObj.inputValueId">
                <p></p>
            </div>

            <div class="inputContainer">
                <p>管理者パスワード</p>
                <input type="password" v-model="reactiveObj.inputValuePassword">
                <p></p>
            </div>

            <div class="errorMessage">
                <p>{{ reactiveObj.errorMessage }}&nbsp;</p>
            </div>

            <div>
                <button class="btnStandard" type="submit" @click="logIn">ログイン</button>
            </div>
        </div>
    </main>
</template>

<style scoped>
    header {
        height: 120px;
    }
    header .title {
        font-size: 40px;
        padding: 20px 0 0;
    }
    main {
        padding: 30px auto;
    }
    .inputContainer {
        position: relative;
        display: flex;
        flex-flow: column;
        align-items: center;
    }
    .inputContainer input {
        width: 400px;
    }
    .inputContainer p {
        width: 200px;
        margin: 30px 0 30px 0;
    }
    .errorMessage p {
        color: red;
    }
    @media (max-width: 1150px) {
        .inputContainer input {
            width: 300px;
        }
    }

</style>