<script setup>
    import { computed, reactive, watch } from 'vue'

    //親コンポーネントから受け継いだ端末情報
    const props = defineProps({
        terminalObject: {
            type: Object,
            require: true
        },
    });
    const emit = defineEmits(['executeMethod']);
    //親コンポーネントから受け継いだ端末の値を保持するリアクティブオブジェクト
    const reactiveObj = reactive({
        //ドア（端末名）
        TerminalName: '',
        //設置先
        Location: '',
        //親機/子機/単独
        TerminalType: '',
        //施錠までの時間
        LockTime: '',
        //接続状態(一時的に実装)
        Connection: ''
    })
    //端末の情報の変化を確認するためのリアクティブオブジェクト
    const reactiveObjFlag = reactive({
        //ドア（端末名）
        TerminalNameFlag: false,
        //設置先
        LocationFlag: false,
        //親機/子機/単独
        TerminalTypeFlag: false,
        //施錠までの時間
        LockTimeFlag: false,
        //接続状態(一時的に実装)
        ConnectionFlag: false
    })
    //変更するドアの情報を格納するオブジェクト
    let editTerminalObject = {}
    //施錠時間のマッピング
    const lockTimeList = {
        'none': 'none',
        '5': '5秒',
        '7': '7秒',
        '10' : '10秒',
        '15' : '15秒',
        '20' : '20秒',
        '30' : '30秒',
        '0': '自動で施錠しない',
        '-1': 'オープンセンサーを使用'
    }
    //モーダルウィンドウが閉じるときに実行
    const exitModal = (editFlag) => {
        //モーダルウィンドウが閉じるときに入力されていた情報を全てリセットする
        for(let key in reactiveObj){
            reactiveObj[key] = ''
        }
        //モーダルウィンドウが閉じるときに変更ボタンを非活性化
        document.getElementById('commonButtonEdit').disabled = true;
        //モーダルウィンドウ外を押下されたときは何もせずに閉じる
        if(!editFlag) {
            emit('executeMethod', false);
        }
        else {
            const modalTerminal = document.getElementById('modalTerminal').value;
            const modalLocation = document.getElementById('modalLocation').value
            const modalTerminalType = document.getElementById('modalTerminalType').value
            const editConnection = document.getElementById('editConnection').value
            //lockTimeListから逆引きして抽出
            const modalLocktime = Number((Object.keys(lockTimeList).filter((key) => {
                return lockTimeList[key] == document.getElementById('modalLocktime').value
            }))[0]);
            //親コンポーネントに変更した情報を渡すためのオブジェクト
            editTerminalObject = {
                'terminalId': props.terminalObject['terminalId'],
                'editTerminalInfo': {
                    'TerminalName': modalTerminal,
                    'Location': modalLocation,
                    'TerminalType': modalTerminalType,
                    'LockTime': modalLocktime,
                    'LatestUpdated': Date.now(),
                    "EntryExit": modalLocation == "内側" ? "退室" : "入室",
                    "Connection": editConnection == "false" ? false : true
                }
            }
            //変更される情報が１つ以上あればモダールを閉じて親コンポーネントに渡す
            emit('executeMethod', true, editTerminalObject);
        }
        return
    }
    //値が更新された際にどのプロパティが変更されたかを検知して変更ボタンを活性化させる
    const inputSomeData = () => {
        //活性判定フラグ
        let disableFlag = true
        for(let key in reactiveObj){
            //以下の条件でTrueとする
            //1.親コンポーネントから受け継いだ値と変更したい値が異なりかつ項目が「LockTime」でないかつ変更したい値が空白でないこと
            //2.親コンポーネントから受け継いだ値と変更したい値が異なりかつ項目が「LockTime」でありかつ変更したい値が空白でないこと
            if((props.terminalObject[key] != reactiveObj[key] && reactiveObj[key] != '' && key != 'LockTime') || (lockTimeList[props.terminalObject['LockTime']] != reactiveObj['LockTime'] && reactiveObj['LockTime'] != '' && key == 'LockTime')) {
                reactiveObjFlag[key] = true
            }
            else {
                reactiveObjFlag[key] = false
            }
        }
        //上記で更新したreactiveObjFlagにTrueがあれば活性判定フラグTrueにする
        for(let key in reactiveObjFlag){
            if(reactiveObjFlag[key] == true) {
                disableFlag = false
            }
        }
        //活性判定フラグTrueなら変更ボタンを活性化そうでないなら非活性化
        disableFlag ? document.getElementById('commonButtonEdit').disabled = true : document.getElementById('commonButtonEdit').disabled = false
    }
    //端末名に関する算出プロパティ
    const getTerminalName = computed({
        get: () => {
            return props.terminalObject['TerminalName']
        },
        set: (value) => {
            reactiveObj.TerminalName = value
            document.getElementById('modalTerminal').value = value
        }
    })
    //親子関係に関する算出プロパティ
    const getTerminalType = computed({
        get: () => {
            return props.terminalObject['TerminalType']
        },
        set: (value) => {
            reactiveObj.TerminalType = value
            document.getElementById('modalTerminalType').value = value
        }
    })
    //設置場所に関する算出プロパティ
    const getLocation = computed({
        get: () => {
            return props.terminalObject['Location']
        },
        set: (value) => {
            reactiveObj.Location = value
            document.getElementById('modalLocation').value = value
        }
    })
    //設置場所に関する算出プロパティ
    const getLocktime = computed({
        get: () => {
            return lockTimeList[String(props.terminalObject['LockTime'])]
        },
        set: (value) => {
            reactiveObj.LockTime = value
            document.getElementById('modalLocktime').value = value
        }
    })
    //接続状況に関する算出プロパティ
    const getConnection = computed({
        get: () => {
            return props.terminalObject['Connection']
        },
        set: (value) => {
            reactiveObj.Connection = value
            document.getElementById('editConnection').value = value
        }
    })
    //リアクティブオブジェクトの監視
    watch(reactiveObj, () => {
        inputSomeData()
    })

</script>

<template>
    <div class="modalArea">
        <div id="modalContent" class="modal">
            <p class="modalTitle">端末情報の変更</p>
            <div class="modalInfo">
                <p>端末名</p>
                <input id="modalTerminal" class="modalTerminal" v-model="getTerminalName">
            </div>
            <div class="modalInfo">
                <p>設置先</p>
                <select id="modalLocation" class="modalLocation" v-model="getLocation">
                    <option value="外側">外側</option>
                    <option value="内側">内側</option>
                </select>
            </div>
            <div class="modalInfo">
                <p>親子設定</p>
                <select id="modalTerminalType" class="modalTerminalType" v-model="getTerminalType">
                    <option value="parent">親機</option>
                    <option value="child">子機</option>
                    <option value="single">単独</option>
                </select>
            </div>
            <div class="modalInfo">
                <p>施錠までの時間</p>
                <select id="modalLocktime" class="modalLocktime" v-model="getLocktime">
                    <option value="5秒">5秒</option>
                    <option value="7秒">7秒</option>
                    <option value="10秒">10秒</option>
                    <option value="15秒">15秒</option>
                    <option value="20秒">20秒</option>
                    <option value="30秒">30秒</option>
                    <option value="自動で施錠しない">自動で施錠しない</option>
                    <option value="オープンセンサーを使用">オープンセンサーを使用</option>
                </select>
            </div>
            <div class="modalInfo">
                <p>接続設定</p>
                <select id="editConnection" class="editConnection" v-model="getConnection">
                    <option value="false">接続を解除する</option>
                    <option value="true">接続（を維持）する</option>
                </select>
            </div>
            <button id="commonButtonEdit" @click="exitModal(true)" disabled>変更する</button>
        </div>
        <div class="modalOverlay" @click.self="exitModal(false)"></div>
    </div>
</template>

<style scoped>
    .modal {
        width: 700px;
        height: 600px;
        top: 50%;
        left: 50%;
    }
    #commonButtonEdit {
        width: 190px;
        height: 70px;
        top: 10%;
        left: 50%;
    }
    @media (max-width: 1150px) {
        .modal {
            width: 85%;
            height: auto;
            display: flex;
            justify-content: left;
            align-items: left;
            overflow-y: scroll;
            overflow-x: hidden;
        }
        .modal p {
            padding: 10px 0 10px 0;
        }
        .modalInfo {
            flex-direction: column;
            padding: 0 0 0 0;
            position: relative;
        }
        .modalInfo p {
            position: relative;
            top: 0%;
            width: 100%;
            font-size: 16px;
        }
        #commonButtonEdit {
            margin: 20px 0 20px 0;
            top: 30px;
            height: auto;
        }
    }
</style>