const errorResult = (error, pathName, kargs = {}) => {
    const firebaseErrorCodes = {
        'adminpassword': {
            'auth/invalid-credential': 'APE0001',
            'auth/password-does-not-meet-requirements': 'APE0003',
            'auth/too-many-requests': 'APE0004',
            'auth/network-request-failed': 'APE0005'
        },
        'login': {
            'auth/invalid-credential': 'LVE0001',
            'auth/wrong-password': 'LVE0001',
            'auth/invalid-email': 'LVE0001',
            'auth/user-not-found': 'LVE0001',
            'auth/too-many-requests': 'LVE0004',
            'auth/network-request-failed': 'LVE0005'
        },
        'userpasswordset': {
            'auth/password-does-not-meet-requirements': 'PSE0003',
            'auth/network-request-failed': 'PSE0004'
        },
        'userpassword': {
            'auth/invalid-credential': 'PVE0001',
            'auth/wrong-password': 'PVE0001',
            'auth/invalid-email': 'PVE0001',
            'auth/user-not-found': 'PVE0001',
            'auth/too-many-requests': 'PVE0003',
            'auth/password-does-not-meet-requirements': 'PVE0004',
            'auth/network-request-failed': 'PVE0007'
        }
    };
    const selfErrorCodes = {
        //NavigationBar(NBE)
        'NBE0001': 'ログアウト処理でエラーが発生しました。',
        //AdminPasswordView(APE)
        'APE0001': '現在のパスワードが誤っています。',
        'APE0002': '入力された新しいパスワードが一致しません。',
        'APE0003': '以下のパスワードの要件が満たされるものを入力してください。\n・6文字以上\n・パスワードは大文字が含まれていること\n・パスワードは小文字が含まれていること\n・パスワードは数字が含まれていること\n・パスワードに記号が含まれていること',
        'APE0004': '規定回数以上誤ったログイン情報が入力されました。\n再度時間を置いて試すかパスワードの再設定をしてください。',
        'APE0005' : 'ネットワークに接続されていません。接続情報を確認してください',
        //AgreeView(AVE)
        //AppSettingsView(ASE)
        'ASE0001': '対応している画像は10MBかつ以下の拡張子となり、ファイルサイズまたは拡張子が異なる画像は除外されます。\n.jpg .jpeg .png',
        'ASE0002': '読み取り中にエラーが発生しました。ページを更新して再度やり直してください。',
        //EditAdminView(EAE)
        //EntryExitLogsView(ELE)
        'ELE0001': '検索したい開始日と終了日を入力してください。',
        'ELE0002': '開始日と終了日が正しくありません。',
        'ELE0003': 'データの読み込みに失敗しました。再度時間を置いて行ってください。',
        'ELE0004': '出力したいログを検索より指定してから実行してください。',
        //LoginView(LVE)
        'LVE0001': 'IDまたはパスワードが間違っています。',
        'LVE0002': '管理者でないため、ログインできませんでした。',
        'LVE0003': 'ログアウト処理でエラーが発生しました。',
        'LVE0004': '規定回数以上誤ったログイン情報が入力されました。\n一時的にこのアカウントのログインは無効になっています。',
        'LVE0005': 'ネットワークに接続されていません。接続情報を確認してください',
        //RequestView(RVE)
        //TerminalStatusView(TSE)
        'TSE0001': '親子設定されている端末を単独に変更することはできません。端末追加より新規に作成してください。',
        'TSE0002': '単独設定されている端末を親機/子機に変更することはできません。端末追加より新規に作成してください。',
        //UserPasswordResetView(PRE)
        //UserPasswordSetView(PSE)
        'PSE0001': '有効期限が切れています。正規の手順で再度実行をしてください。',
        'PSE0002': '入力された新しいパスワードが一致しません。',
        'PSE0003': '以下のパスワードの要件が満たされるものを入力してください。\n・6文字以上\n・パスワードは大文字が含まれていること\n・パスワードは小文字が含まれていること\n・パスワードは数字が含まれていること\n・パスワードに記号が含まれていること',
        'PSE0004': 'ネットワークに接続されていません。接続情報を確認してください',
        //UserPasswordView(PVE)
        'PVE0001': 'IDまたはパスワードが間違っています。',
        'PVE0002': '入力された新しいパスワードが一致しません。',
        'PVE0003': '規定回数以上誤ったログイン情報が入力されました。\n一時的にこのアカウントのログインは無効になっています。',
        'PVE0004': '以下のパスワードの要件が満たされるものを入力してください。\n・6文字以上\n・パスワードは大文字が含まれていること\n・パスワードは小文字が含まれていること\n・パスワードは数字が含まれていること\n・パスワードに記号が含まれていること',
        'PVE0005': '企業コードまたはURLが違います。再度、新規登録時のメールリンクからお試しください。',
        'PVE0006': 'ログアウト処理でエラーが発生しました。',
        'PVE0007': 'ネットワークに接続されていません。接続情報を確認してください',
        //UserRegistrationView(RVE)
        'RVE0001': '対応している画像は10MBかつ以下の拡張子となり、ファイルサイズまたは拡張子が異なる画像は除外されます。\n.jpg .jpeg .png',
        'RVE0002': '読み取り中にエラーが発生しました。ページを更新して再度やり直してください。',
        'RVE0003': 'CSVファイルをアップロードしてください。',
        'RVE0004': '正しい形式のCSVファイルをアップロードしてください。',
        'RVE0005': '先に「CSVファイル参照」もしくは「画像ファイル参照」より\n更新するファイルをアップロードしてください。',
        'RVE0006': 'IDとメールアドレスの入力は必須です。',
        'RVE0007': `以下の該当者の変更しようとしているメールアドレスが他の利用者と重複しています。\n${kargs.message}`,
        //AddCorporateComponent(ACE)
        'ACE0001': 'メールアドレスの形式に準拠していません。',
        'ACE0002': '企業コードは半角英数字のみを入力してください。',
        'ACE0003': '既に同じ企業コードが存在しています。別の企業コードを入力してください。',
    }
    const firebaseErrorCode = firebaseErrorCodes[pathName] && firebaseErrorCodes[pathName][error.code] ? firebaseErrorCodes[pathName][error.code] : false
    const selfErrorMessage = firebaseErrorCode ?
        selfErrorCodes[firebaseErrorCode]: selfErrorCodes[error.code] ?
            selfErrorCodes[error.code] :
            error.message

    return selfErrorMessage ? selfErrorMessage : `不明なエラー検知しました\nerrorCode: ${error.code}\nerrorMessage: ${error.message}`
};
export default { errorResult };
