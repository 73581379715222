<script setup>
    import { reactive, watch } from 'vue'
    import Error from "../../firebase_settings/error.js"
    import { useRoute } from 'vue-router'

    const route = useRoute()
    const emit = defineEmits(['executeMethod']);
    const props = defineProps({
        otherObject: {
            type: Object,
            require: true
        },
    });
    //パスワードの値を保持するリアクティブオブジェクト
    const reactiveObj = reactive({
        //登録する企業名
        corporateName: "",
        //担当者名
        userName: "",
        //登録する企業コード
        corporateId: "",
        //登録する管理者のメールアドレス
        adminEmail: "",
    })
    //全ての入力フォームが入力されたときに変更ボタンを活性化させる
    const inputSomeData = () => {
        //変更ボタンの活性化フラグ
        let equalFlag = false
        //全ての入力フォームが入力されたときに変更ボタンの活性化フラグをTrueにする
        if(reactiveObj.corporateName && reactiveObj.userName && reactiveObj.corporateId && reactiveObj.adminEmail) {
            equalFlag = true
        }
        //活性判定フラグTrueなら変更ボタンを活性化そうでないなら非活性化
        equalFlag ? document.getElementById('commonButtonAdd').disabled = false: document.getElementById('commonButtonAdd').disabled = true
    }
    //リアクティブオブジェクトのリセット
    const valueReset = () => {
        reactiveObj.corporateName = ''
        reactiveObj.userName = ''
        reactiveObj.corporateId = ''
        reactiveObj.adminEmail = ''
    }
    //先頭文字の大文字判定
    const isUpperCase = (str) => {
        const arr = str.match(/^[A-Z]+$/)
        return arr ? true : false
    }
    const exitModal = (addFlag) => {
        if(addFlag) {
            //メールアドレスを正規表現でチェック
            const extentionRegExp = new RegExp(/[a-z\d][\w.+_-]+@[\w.-]+\.[a-z\d]/, 'i')
            if(!extentionRegExp.test(reactiveObj.adminEmail)) {
                alert(Error.errorResult({ code: 'ACE0001' }, route.name))
                return
            }
            //企業コードを正規表現でチェック（半角英数字のみ）
            if (!reactiveObj.corporateId.match(/^[A-Za-z0-9]*$/)) {
                alert(Error.errorResult({ code: 'ACE0002' }, route.name))
                return
            }
            //企業コードの頭文字を大文字に変換する
            if(!isUpperCase(reactiveObj.corporateId)) {
                reactiveObj.corporateId = reactiveObj.corporateId.charAt(0).toUpperCase() + reactiveObj.corporateId.slice(1);
            }
            //企業コードの重複確認
            let duplicateFlag = false
            for(let corporateId of props.otherObject.corporateIdList) {
                if(corporateId == reactiveObj.corporateId) {
                    duplicateFlag = true
                    break
                }
            }
            if(duplicateFlag) {
                alert(Error.errorResult({ code: 'ACE0003' }, route.name))
                return
            }
        }
        //親コンポーネントに編集の是非とその値を渡す
        emit('executeMethod', addFlag, reactiveObj);
        //モーダルウィンドウを閉じる際に追加ボタンを非活性化
        document.getElementById('commonButtonAdd').disabled = true;
        valueReset()
    }
    //リアクティブオブジェクトの監視
    watch(reactiveObj, () => {
        inputSomeData()
    })
</script>

<template>
    <div class="modalArea">
        <div id="modalContent" class="modal">
            <p class="modalTitle">企業情報の追加</p>
            <div class="modalInfo">
                <p>企業名</p>
                <input type="text" class="modalInput" v-model="reactiveObj.corporateName" placeholder="株式会社">
            </div>
            <div class="modalInfo">
                <p>担当者名</p>
                <input type="text" class="modalInput" v-model="reactiveObj.userName" placeholder="担当者名">
            </div>
            <div class="modalInfo">
                <p>企業コード（半角英数字のみ）</p>
                <input type="text" class="modalInput" v-model="reactiveObj.corporateId" placeholder="CorporateName">
            </div>
            <div class="modalInfo">
                <p>管理者のメールアドレス</p>
                <input type="text" class="modalInput" v-model="reactiveObj.adminEmail" placeholder="aaa@cotofure.com">
            </div>
            <button type="button" id="commonButtonAdd" @click="exitModal(true)" disabled>追加する</button>
        </div>
        <div class="modalOverlay" @click.self="exitModal(false)"></div>
    </div>
</template>

<style scoped>
    .modal {
        width: 700px;
        height: 500px;
        top: 50%;
        left: 50%;
    }
    #commonButtonAdd {
        width: 190px;
        height: 70px;
        top: 12%;
        left: 50%;
    }
    .modalInfo {
        display: flex;
        flex-direction: row;
        padding: 0 3% 0 3%;
    }
    .modalInput {
        height: 50px;
        width: 500%;
        border-radius: 10px;
        border: solid 3px #d7d7d7;
        background-color: #d7d7d7;
    }
    .describe {
        width: 500%;
        text-align: left;
    }
    @media (max-width: 1150px) {
        .modal {
            width: 85%;
            height: auto;
            display: flex;
            justify-content: left;
            align-items: left;
            overflow-y: scroll;
            overflow-x: hidden;
        }
        .modal p {
            padding: 10px 0 10px 0;
        }
        .modalInfo {
            flex-direction: column;
            padding: 3% 0 3% 0;
            position: relative;
        }
        .modalInfo p {
            position: relative;
            top: 0%;
            width: 100%;
            max-width: 100%;
            font-size: 16px;
        }
        .modalInput {
            height: 50px;
            width: 100%;
            border-radius: 10px;
            border: solid 3px #d7d7d7;
            background-color: #d7d7d7;
        }
        .describe {
            width: 100%;
            text-align: left;
        }
        #commonButtonAdd {
            margin: 20px 0 20px 0;
            top: 30px;
            height: auto;
        }
    }
</style>