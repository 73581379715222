
<script setup>
    import { computed, reactive, onMounted } from 'vue'
    import { onAuthStateChanged, signOut } from "firebase/auth";
    import { useRouter } from 'vue-router'
    import { ref as databaseRef, onValue, update, push, child, set } from "firebase/database";
    import { formatToTimeZone } from 'date-fns-timezone';
    import { useRoute } from 'vue-router'
    import HeaderComponent from "../../components/common/HeaderComponent.vue"
    import EditTeminalComponent from "../../components/business/EditTeminalComponent.vue"
    import AddTeminalComponent from "../../components/business/AddTeminalComponent.vue"
    import NavigationBar from "../../components/business/NavigationBar.vue"
    import DefaultModalComponent from '../../components/common/DefaultModalComponent.vue'
    import LockStatusComponent from '../../components/business/LockStatusComponent.vue'
    import PaginateComponent from 'vuejs-paginate-next'
    import Firebase from "../../firebase_settings/index.js"
    import Error from "../../firebase_settings/error.js"

    const FORMAT = 'YYYY/MM/DD HH:mm:ss';
    const TIME_ZONE_TOKYO = 'Asia/Tokyo';
    const auth = Firebase.auth
    const database = Firebase.database;
    const router = useRouter()
    const route = useRoute()

    //端末情報等のリアクティブオブジェクト
    const reactiveObj = reactive({
        //企業ID
        corporateId: "",
        //ドア情報一覧
        terminalObjects: [],
        //子コンポーネントに渡すようのドア情報
        terminalObject: {},
        //再レンダリング用のキー
        renderKey: 0,
        //現在のページ（デフォルトは１ページ）
        currentPage: 1,
        //端末追加用のモーダル
        addModalFlag: false,
        //端末編集用モーダル
        editModalFlag: false,
        //端末削除用モーダル
        deleteModalFlag: false,
        //単体施錠用モーダル
        lockModalFlag: false,
        //削除用モーダルメッセージ
        modalMessage: "",
        //削除用モーダルメッセージ(赤字)
        modalRedMessage: "",
        //主に削除用に使用
        otherObject: {},
        //単体施錠判別フラグ
        CompletelyLock: false,
        //夜間施錠判別フラグ
        NightFlag: false
    })
    const reactiveNowObject = reactive({
        CurrentTime: Date.now()
    })
    //1ページに表示する件数
    const perPage = 10;
    //firebaseからログイン利用者の情報を取得
    const getTerminals = async() => {
        //ログイン情報をチェック
        onAuthStateChanged(auth, (user) => {
            if (user) {
                //DBの取得したいパスを指定
                const getData = databaseRef(database, `LoginUser/${user.uid}`)
                //DBを取得
                onValue(getData, (snapshot) => {
                    //企業コードを取得できている場合には処理を継続、そうでない場合はサインアウトする（恐らくサインアウトはされないはず）
                    try {
                        reactiveObj.corporateId = snapshot.val()["CorporateId"] }
                    catch {
                        signOut(auth)
                        .then(() => {
                            router.push('/login')
                        })
                        .catch((error) => {});
                    }
                    const terminalsRef = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/Terminals`)
                    //firebaseからドア情報を取得する
                    onValue(terminalsRef, (snapshot) => {
                        reactiveObj.terminalObjects = []
                        const terminals = (snapshot.val())
                        for (let key in terminals) {
                            reactiveObj.terminalObjects.push({
                                'terminalId': key,
                                'TerminalName': terminals[key]['TerminalName'],
                                'Location': terminals[key]['Location'],
                                'LockTime': terminals[key]['LockTime'],
                                'TerminalType': terminals[key]['TerminalType'],
                                'TerminalPair': terminals[key]['TerminalPair'],
                                'Connection': terminals[key]['Connection'],
                                'DeviceType': terminals[key]['DeviceType'],
                                'DisplayLatestUpdated': terminals[key]["DisplayLatestUpdated"] ? formatToTimeZone(terminals[key]["DisplayLatestUpdated"], FORMAT, { timeZone: TIME_ZONE_TOKYO }) : "",
                                'EntryExit': terminals[key]['EntryExit'],
                                'LatestUpdated': formatToTimeZone(terminals[key]["LatestUpdated"], FORMAT, { timeZone: TIME_ZONE_TOKYO }),
                                'CompletelyLock': terminals[key]['CompletelyLock'],
                                'RemoteInput': terminals[key]['RemoteInput'],
                                'AllowViewAccount':  terminals[key]['AllowViewAccount'],
                            })
                        }
                    })
                    reactiveObj.renderKey++
                })
            } else {
            }
        })
    }
    //n個目のモーダルを表示する
    const showEditModal = (data, key) => {
        reactiveObj.editModalFlag = true
        reactiveObj.terminalObject = data
    }
    //モーダルを閉じる
    const closeEditModal = async (editFlag, editTerminalObject) => {
        //n個目のモーダルを閉じる
        reactiveObj.editModalFlag = false
        //更新対象の判別
        if(editFlag) {
            //入力が単独かつ既存の設定の値に親子設定がされている場合
            if(editTerminalObject['editTerminalInfo']['TerminalType'] == 'single' && reactiveObj.terminalObject['TerminalPair']){
                alert(Error.errorResult({ code: 'TSE0001'}, route.name))
                return
            }
            //入力が親機/子機かつ親子設定がされていない場合
            else if(editTerminalObject['editTerminalInfo']['TerminalType'] != 'single' && !reactiveObj.terminalObject['TerminalPair']){
                alert(Error.errorResult({ code: 'TSE0002'}, route.name))
                return
            }
            //上記以外
            else {
                const terminalData = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/Terminals/${editTerminalObject['terminalId']}`);
                //入力された値に更新
                await update(terminalData, editTerminalObject['editTerminalInfo']);
                if(editTerminalObject['editTerminalInfo']['TerminalType'] != 'single') {
                    //更新された端末の設定内容に応じて値を設定する
                    const editTerminalPairInfo = {
                        'TerminalName': editTerminalObject['editTerminalInfo']['TerminalName'],
                        'Location': editTerminalObject['editTerminalInfo']['Location'] == '外側' ? '内側' : '外側',
                        'LockTime': editTerminalObject['editTerminalInfo']['LockTime'],
                        'TerminalType': editTerminalObject['editTerminalInfo']['TerminalType'] == 'parent' ? 'child' : 'parent',
                        'EntryExit': editTerminalObject['editTerminalInfo']['EntryExit'] == '入室' ? '退室' : '入室',
                        'LatestUpdated': editTerminalObject['editTerminalInfo']['LatestUpdated']
                    }
                    //更新された端末の対となる端末を更新する
                    const terminaPairlData = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/Terminals/${reactiveObj.terminalObject.TerminalPair}`);
                    await update(terminaPairlData, editTerminalPairInfo);
                }
            }
        }
        reactiveObj.terminalObject = {}
    }
    //端末追加のモーダルウィンドウを開く際に実行
    const showAddModal = () => {
        //モーダルウィンドウを開く
        reactiveObj.addModalFlag= true
    }
    //端末追加のモーダルウィンドウを閉じる際に実行
    const closeAddModal = (editFlag, newTeminalObj) => {
        //モーダルウィンドウを閉じる
        reactiveObj.addModalFlag= false
        if(editFlag){
            const locationMap = {
                "内側": "退室",
                "外側": "入室"
            }
            let parentId = ""
            let childId = ""
            const terminalPath = `CorporateId/${reactiveObj.corporateId}/Terminals`
            //追加する際に親を選択した場合には自動的に子機も作成するようにする(1回目：親機or単独の作成、2回目：子機の作成)
            for(let i = 0; i < (newTeminalObj.TerminalType == 'parent' ? 2 : 1); i++){
                const newTeminalKey = push(child(databaseRef(database), terminalPath)).key;
                i == 0 ? parentId = newTeminalKey : childId = newTeminalKey
                const data = {
                    "AllowViewAccount": [auth.lastNotifiedUid],
                    "Connection": false,
                    "DeviceType": "Sesame",
                    "LatestUpdated": Date.now(),
                    "DisplayLatestUpdated": 0,
                    "Location": i == 0 ? newTeminalObj.Location :
                        newTeminalObj.Location == "内側" ? "外側" : "内側",
                    "LockTime": Number(newTeminalObj.LockTime),
                    "PermittedGroupId": [
                        "All"
                    ],
                    "TerminalName": newTeminalObj.TerminalName,
                    "TerminalType": i == 0 ? newTeminalObj.TerminalType : 'child',
                    "EntryExit": i == 0 ? locationMap[newTeminalObj.Location] :
                        locationMap[newTeminalObj.Location == "内側" ? "外側" : "内側"],
                    'CompletelyLock': false,
                    'RemoteInput': ''
                }
                //端末を追加
                update(databaseRef(database, `${terminalPath}/${newTeminalKey}`), data)
            }
            //親子関係の設定
            update(databaseRef(database, `${terminalPath}/${parentId}`), {"TerminalPair": childId})
            if(childId) {
                update(databaseRef(database, `${terminalPath}/${childId}`), {"TerminalPair": parentId})
            }
        }
    }
    //端末削除のモーダルウィンドウを開く際に実行
    const showDeleteModal = (data, key) => {
        //子コンポーネントに渡すオブジェクト
        reactiveObj.otherObject = {
            terminalIndex: key,
            confirm: '削除する',
            modalWidth: "35%",
        }
        //モーダルウィンドウを開く
        reactiveObj.deleteModalFlag = true
        //削除対象が親機か子機（単独）に応じてメッセージを変更する
        let TerminalType = data.TerminalType == 'parent'? '親機': data.TerminalType == 'child'? '子機' : '単独'
        //モーダルウィンドウに表示するメッセージを設定　※ここでHTMLの設定しないと反映できない
        reactiveObj.modalMessage = `以下の端末を削除します。<br>よろしいですか？<br><br>端末名：${data.TerminalName}<br>設置先：${data.Location}<br>親子設定：${TerminalType}`
        reactiveObj.modalRedMessage = data.TerminalType == 'parent' ? "※親機を削除すると、子機も同時に削除されます。" : ""
        //モーダルウィンドウを再描画
        reactiveObj.renderKey++
    }
    //端末削除のモーダルウィンドウを閉じる際に実行
    const closeDeleteModal = async (deleteFlag) => {
        //削除対象の番号
        const terminalIndex = reactiveObj.otherObject.terminalIndex
        //モーダルウィンドウを閉じる
        reactiveObj.deleteModalFlag = false
        //削除に同意しているか
        if(deleteFlag) {
            const terminalData = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/Terminals/${reactiveObj.terminalObjects[terminalIndex]['terminalId']}`);
            const terminalPairlData = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/Terminals/${reactiveObj.terminalObjects[terminalIndex]['TerminalPair']}`);
            //親子設定の状態に分けて処理を行う
            switch (reactiveObj.terminalObjects[terminalIndex]['TerminalType']) {
                //親機を削除する場合
                case 'parent':
                    //対となる端末（子機）が存在しているなら（子機を）削除する
                    if(reactiveObj.terminalObjects[terminalIndex]['TerminalPair']) {
                        await set(terminalPairlData, {})
                    }
                    break
                //子機を削除する場合
                case 'child':
                    //対となる端末（親機）が存在しているなら（親機の）親子関係を解除し、単独に変更する
                    if(reactiveObj.terminalObjects[terminalIndex]['TerminalPair']) {
                        await update(terminalPairlData, {
                            "TerminalPair": "",
                            "TerminalType": "single"
                        })
                    }
                    break
                //単独を削除する場合
                case 'single':
                    //何もしない
                    break
            }
            //端末を削除
            await set(terminalData, {})
        }
        //子コンポーネントに渡すオブジェクトをリセット
        reactiveObj.otherObject = {}
    }
    //端末追加のモーダルウィンドウを開く際に実行
    const showStatusModal = (data, key) => {
        //モーダルウィンドウを開く
        reactiveObj.lockModalFlag = true
        reactiveObj.terminalObject = data
    }
    const closeStatusModal = async (modeFlag, move) => {
        reactiveObj.lockModalFlag = false
        if(modeFlag) {
            const terminalData = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/Terminals/${reactiveObj.terminalObject.terminalId}`);
            await update(terminalData, {RemoteInput: move});
        }
        reactiveObj.terminalObject = {}
    }
    //ページネーションを押下した時
    const clickCallback = (pageNum) => {
        reactiveObj.currentPage = Number(pageNum);
    }
    //Nページ目に表示するドア情報を取得
    const getItems = computed(() => {
        let current = reactiveObj.currentPage * perPage;
        let start = current - perPage;
        return reactiveObj.terminalObjects.slice(start, current);
    })
    //現在のページ数を取得
    const getPageCount = computed(() => {
        return Math.ceil(reactiveObj.terminalObjects.length / perPage) ? Math.ceil(reactiveObj.terminalObjects.length / perPage) : 1
    })
    const isWithinTimeRange = (start, end, current) => {
        return start <= end
            ? current >= start && current < end
            : current >= start || current < end;
    };
    //企業コードを取得
    const getCorporateId = () => {
        //同期処理
        return new Promise((resolve) => {
            //ログインチェック
            onAuthStateChanged(auth, (user) => {
                const getData = databaseRef(database, `LoginUser/${user.uid}`);
                onValue(getData, (snapshot) => {
                    resolve(snapshot.val()["CorporateId"] ? snapshot.val()["CorporateId"] : "");
                })
            })
		})
    }
    const getAppSetting = async () => {
        const settingsRef = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/AppSettings`)
            //firebaseからアプリ情報を取得する
            await onValue(settingsRef, (snapshot) => {
                const settings = (snapshot.val())
                const NightStart = settings["NightStart"]
                const NightEnd = settings["NightEnd"]
                reactiveNowObject.currentTime = new Date().getHours() * 100 + new Date().getMinutes()
                reactiveObj.NightFlag = settings["NightFlag"] && isWithinTimeRange(NightStart, NightEnd, reactiveNowObject.currentTime)
            })
    }
    //コンポーネントがマウントされた後に実行
    onMounted(async () => {
        //端末一覧を取得する
        reactiveObj.corporateId = await getCorporateId()
        await getTerminals()
        await getAppSetting()
    })
</script>
<template>
    <!-- コメントアウト用 -->
    <HeaderComponent></HeaderComponent>
    <NavigationBar></NavigationBar>
    <main>
        <div class="terminalStatus" :key="`${reactiveObj.renderKey}-terminalStatus`">
            <div class="titleArea">
                <p class="title">端末状況</p>
                <button id="addButton" @click="showAddModal">端末追加</button>
                <AddTeminalComponent v-show="reactiveObj.addModalFlag" @executeMethod="closeAddModal"></AddTeminalComponent>
            </div>
            <div class="tableArea">
                <table class="commonTable">
                    <tbody>
                        <tr>
                            <th id="terminal" class="leftSideTh">端末名</th>
                            <th id="completelyLock">状況</th>
                            <th id="parent">親子設定</th>
                            <th id="displayLatest" class="leftSideTh">最終更新日時</th>
                            <th id="edit">編集</th>
                            <th id="delete">削除</th>
                        </tr>
                        <tr v-for="(data, key) in getItems" :key="key">
                            <td class="leftSideTd">{{ data.TerminalName }}({{ data.Location }})</td>
                            <td class="leftSideTd completelyLock" @click="showStatusModal(data, (reactiveObj.currentPage-1)*perPage+key)">
                                {{
                                    data.CompletelyLock ?  "単体施錠" :
                                        reactiveObj.NightFlag ? "夜間施錠" : "稼働中"
                                }}
                            </td>
                            <td>{{ data.TerminalType == 'parent'? '親機': data.TerminalType == 'child'? '子機' : '単独' }}</td>
                            <td class="leftSideTd">{{ data.DisplayLatestUpdated  }}</td>
                            <td><button type="button" class="editButton" @click="showEditModal(data, (reactiveObj.currentPage-1)*perPage+key)"><img src="../../assets/pencil.png" id="pencilIcon"></button></td>
                            <td><button type="button" class="deleteButton" @click="showDeleteModal(data, (reactiveObj.currentPage-1)*perPage+key)"><img src="../../assets/delete.png" id="deletelIcon"></button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <EditTeminalComponent
                :key="reactiveObj.renderKey"
                :terminalObject="reactiveObj.terminalObject"
                v-show="reactiveObj.editModalFlag"
                @executeMethod="closeEditModal">
            </EditTeminalComponent>
            <LockStatusComponent
                :key="reactiveObj.renderKey"
                :terminalObject="reactiveObj.terminalObject"
                :NightFlag="reactiveObj.NightFlag"
                v-show="reactiveObj.lockModalFlag"
                @executeMethod="closeStatusModal">
            </LockStatusComponent>
            <DefaultModalComponent
                :key="reactiveObj.renderKey"
                :modalMessage="reactiveObj.modalMessage"
                :modalRedMessage="reactiveObj.modalRedMessage"
                :otherObject="reactiveObj.otherObject"
                v-show="reactiveObj.deleteModalFlag"
                @executeMethod="closeDeleteModal">
            </DefaultModalComponent>

            <div class="paginationArea">
                <!--
                <PaginateComponent
                    :page-count="getPageCount"
                    :click-handler="clickCallback"
                    :page-range="5"
                    :prev-text="reactiveObj.currentPage >= 2 ? '<' : ''"
                    :next-text="reactiveObj.currentPage < parseInt(reactiveObj.terminalObjects.length / 10) + 1 ? '>' : ''"
                    :container-class="'pagination'"
                    :page-class="'page-item'">
                </PaginateComponent>
                -->
                <PaginateComponent
                    :page-count="getPageCount"
                    :click-handler="clickCallback"
                    :page-range="5"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :container-class="'pagination'"
                    :page-class="'page-item'">
                </PaginateComponent>
            </div>
        </div>
    </main>
</template>

<style scoped>
    .terminalStatus{
        padding: 0 5% 0 25%;
        background-color: #e7e6e6;
    }
    #addButton {
        position: relative;
        display: flex;
        margin-left: auto;
        top: -40px;
        border: solid 3px #7CD5F5;
        background: #7CD5F5;
    }
    .commonTable #terminal{
        width: 45%;
    }
    .commonTable #completelyLock{
        width: 10%;
    }
    .commonTable #edit{
        width: 10%;
    }
    .commonTable #parent{
        width: 10%;
    }
    .commonTable #displayLatest{
        width: 15%;
        white-space: nowrap
    }
    #naviTerminalStatusView {
        background-color: #32a4ea;
        color: #F5F5F5;
    }
    .completelyLock {
        cursor: pointer;
        color: #0d6efd;
        text-decoration: underline;
        border-color: #4a4a4a;
        white-space: nowrap;
    }
    @media (max-width: 1150px) {
        .terminalStatus{
            padding: 0 5% 0 5%;
        }
        .tableArea {
            overflow-x: scroll;
        }
    }
</style>
