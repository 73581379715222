
<script setup>
    import { useRouter } from 'vue-router'
    import { ref as databaseRef, update } from "firebase/database";
    import HeaderComponent from "../../components/common/HeaderComponent.vue"
    import Firebase from "../../firebase_settings/index.js"

    const auth = Firebase.auth
    const database = Firebase.database;
    const router = useRouter()

    //利用規約に同意するを押下した際に実行
    const agreeTerm = () => {
        const getData = databaseRef(database, `LoginUser/${auth.currentUser.uid}`);
        //FirebaseのDBにある自身の管理者データにある値を更新し端末状況画面に自動遷移
        update(getData, {'Agree': true});
        router.push('/')
    }
</script>
<template>
    <!-- コメントアウト用 -->
    <HeaderComponent></HeaderComponent>
    <main>
        <div class="agreeTermArea">
            <p id="title">ProoFace by SESAME 利用規約</p>
        </div>
        <div class="agreeTerm">
            <p class="notIndent">　この規約は、お客様が、Cotofure株式会社（以下「当社」）が「ProoFace」の名称で提供する顔認証サービス（以下「本サービス」）をご利用頂く際の取扱いにつき定めるものです。本規約に同意した上で本サービスをご利用ください。</p><br>
            <h1>第１条（定義）</h1>
            <br>
            <p class="notIndent">　本規約上で使用する用語の定義は、次に掲げるとおりとします。</p>
            <p>１．本サービス</p>
            <p>　　当社が運営する「ProoFace」の名称で提供する顔認証サービス及び関連するサービス</p>
            <p>２．本サイト</p>
            <p>　　本サービスのコンテンツが掲載されたウェブサイト</p>
            <p>３．本コンテンツ</p>
            <p>　　本サービス上で提供される文字、音、静止画、動画、ソフトウェアプログラム、コード等の総称（投稿情報を含む）</p>
            <p>４．ユーザ</p>
            <p>　　実際に本サービスを利用する登録利用者自身及び登録利用者の役員又は従業員等</p>
            <p>５．登録利用者</p>
            <p>　　本サービスの利用者登録が完了した方</p>
            <p>６．ＩＤ</p>
            <p>　　本サービスの利用のために登録利用者が固有に持つ文字列</p>
            <p>７．パスワード</p>
            <p>　　ＩＤに対応して登録利用者が固有に設定する暗号</p>
            <p>８．個人情報</p>
            <p>　　住所、氏名、職業、電話番号等個人を特定することのできる情報の総称</p>
            <p>９．登録情報</p>
            <p>　　登録利用者が本サイトにて登録した情報（ID、パスワードを含みます。）の総称</p>
            <p>１０．知的財産</p>
            <p class="indent3">　　　　発明、考案、意匠、著作物その他の人間の創造的活動により生み出されるもの（発見または解明がされた自然の法則または現象であって、産業上の利用可能性があるものを含む）、商標、商号その他事業活動に用いられる商品または役務を表示するもの及び営業秘密その他の事業活動に有用な技術上または営業上の情報</p>
            <p>１１．知的財産権</p>
            <p class="indent3">　　　　特許権、実用新案権、意匠権、著作権、商標権その他の知的財産に関して法令により定められた権利または法律上保護される利益に係る権利</p>
            <br>
            <h1>第２条（本規約への同意）</h1>
            <br>
            <p>１．登録利用者は、本規約に同意頂いた上で、本サービスを利用できるものとします。</p>
            <p>２．登録利用者が、本規約への同意手続を行い別途提供する申込書を当社へ送付した時点で、登録利用者と当社との間で、本規約の諸規定に従った利用契約が成立するものとします。</p>
            <p>３．登録利用者が未成年者である場合には、親権者その他の法定代理人の同意を得たうえで、本サービスをご利用ください。　</p>
            <p>４．未成年者の登録利用者が、法定代理人の同意がないにもかかわらず同意があると偽りまたは年齢について成年と偽って本サービスを利用した場合、その他行為能力者であることを信じさせるために詐術を用いた場合、本サービスに関する一切の法律行為を取り消すことは出来ません。</p>
            <p>５．本規約の同意時に未成年であった登録利用者が成年に達した後に本サービスを利用した場合、当該登録利用者は本サービスに関する一切の法律行為を追認したものとみなされます。</p>
            <br>
            <h1>第３条（規約の変更）</h1>
            <br>
            <p>１．当社は、次の各号のいずれかに該当する場合、登録利用者及びユーザの承諾を得ることなく、本規約の内容を変更すること（本規約に新たな内容を追加することを含む。）ができるものとします。</p>
            <p class="indent2">（１）利用規約の変更が、ユーザの一般の利益に適合するとき。</p>
            <p class="indent2">（２）利用規約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき。</p>
            <p>２．当社は、本規約を改定するときは、その内容について当社所定の方法により登録利用者に通知します。</p>
            <p>３．前本規約の改定の効力は、当社が前項により通知を行った時点から生じるものとします。</p>
            <p>４．登録利用者は、本規約変更後、本サービスを利用した時点で、変更後の本利用規約に異議なく同意したものとみなされます。</p>
            <br>
            <h1>第４条（会員の入会手続）</h1>
            <p>１．本サービスへの入会を希望する方（以下「登録希望者」といいます。）は、本規約に同意した上で、所定の方法で入会の申込を行ってください。</p>
            <p>２．入会の申込をした登録希望者は、本規約に同意し、ＩＤ登録が完了した時点から登録利用者となります。</p>
            <p>３．弊社は、登録利用者向けにメールで連絡事項の告知や広告その他の情報提供を行います。あらかじめご了承ください。</p>
            <p>４．弊社は、登録希望者もしくは登録利用者が次の各号のいずれか一つに該当する場合は、弊社の判断により登録の拒否又は登録の取り消しを行うことがあります。</p>
            <p class="indent2">（１）登録希望者が、弊社の定める方法によらず入会の申込を行った場合</p>
            <p class="indent2">（２）登録希望者が、過去に本規約または弊社の定めるその他の利用規約等に違反したことを理由として退会処分を受けた者である場合</p>
            <p class="indent2">（３）登録希望者が、不正な手段をもって登録を行っていると弊社が判断した場合</p>
            <p class="indent2">（４）登録希望者が、本人以外の情報を登録している場合</p>
            <p class="indent2">（５）その他弊社が不適切と判断した場合</p>
            <br>
            <h1>第５条（アカウントの管理）</h1>
            <br>
            <p>１．登録利用者は、登録情報について、自己の責任の下、任意に登録、管理するものとします。登録利用者は、登録情報を第三者に利用させ、または貸与、譲渡、名義変更、売買などをしてはならないものとします。</p>
            <p>２．当社は、登録情報によって本サービスの利用があった場合、利用登録をおこなった本人が利用したものと扱うことができ、当該利用によって生じた結果ならびにそれに伴う一切の責任については、利用登録を行った本人に帰属するものとします。</p>
            <p>３．登録利用者は、登録情報の不正使用によって当社または第三者に損害が生じた場合、当社および第三者に対して、当該損害を賠償するものとします。</p>
            <p>４．登録情報の管理は、登録利用者が自己の責任の下で行うものとし、登録情報が不正確または虚偽であったために登録利用者が被った一切の不利益および損害に関して、当社は責任を負わないものとします。</p>
            <p>５．登録情報が盗用されまたは第三者に利用されていることが判明した場合、登録利用者は直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。</p>
            <br>
            <h1>第６条（個人情報等の取り扱い）</h1>
            <br>
            <p>１．当社は、本サービスの提供に伴い登録利用者から取扱いを委託される個人情報を、当社が別途定める「プライバシーポリシー」に則り、委託の目的となる本サービスの目的の範囲内で適正に取り扱うこととします。</p>
            <p>２．当社は、本サービスの提供に伴い登録利用者から取得する登録情報その他の個人情報を、別紙記載の利用目的で利用するものとし、別紙に定めのない事項については、当社が別途定める「プライバシーポリシー」が適用されるものとします。</p>
            <p>３．登録利用者は、本サービスの利用のために登録利用者以外の個人情報を当社へ提供する必要がある場合、当該個人情報の提供にあたり個人情報の保護に関する法律（平成十五年法律第五十七号）を遵守するものとします。</p>
            <br>
            <h1>第７条（料金及び支払方法）</h1>
            <br>
            <p>１．登録利用者は、本サービス利用の対価として、別途申込書に定める利用料金を負担するものとします。</p>
            <p>２．前項の利用料金の支払方法は銀行振込での支払いとします。</p>
            <p>３．第１項に定める金銭債務の支払いを怠ったときは、支払日の翌日から支払い済みまで年14.6パーセントの割合による遅延損害金を支払う（１年を３６５日として日割計算する）</p>
            <br>
            <h1>第８条（禁止行為）</h1>
            <br>
            <p class="notIndent">　本サービスの利用に際し、当社は、登録利用者に対し、次に掲げる行為を禁止します。当社において、登録利用者が禁止事項に違反したと認めた場合、IDの一時停止、退会処分その他当社が必要と判断した措置を取ることができます。</p>
            <p>１．当社または第三者の知的財産権を侵害する行為</p>
            <p>２．当社または第三者の名誉・信用を毀損または不当に差別もしくは誹謗中傷する行為</p>
            <p>３．当社または第三者の財産を侵害する行為、または侵害する恐れのある行為</p>
            <p>４．当社または第三者に経済的損害を与える行為</p>
            <p>５．当社または第三者に対する脅迫的な行為</p>
            <p>６．コンピューターウィルス、有害なプログラムの使用またはそれを誘発する行為</p>
            <p>７．本サービス用インフラ設備に対して過度な負担となるストレスをかける行為</p>
            <p>８．当サイトのサーバーやシステム、セキュリティへの攻撃</p>
            <p>９．当社提供のインターフェース以外の方法で当社サービスにアクセスを試みる行為</p>
            <p>１０．一人の登録利用者が、複数のIDを取得する行為</p>
            <p>１１．第１号ないし第１０号までの行為をユーザに行わせる行為</p>
            <p>１２．上記の他、当社が不適切と判断する行為</p>
            <br>
            <h1>第９条（免責）</h1>
            <br>
            <p>１．当社は、本サービスの内容変更、中断、終了によって生じたいかなる損害についても、一切責任を負いません。</p>
            <p>２．当社は、ユーザの本サービスの利用環境について一切関与せず、また一切の責任を負いません。</p>
            <p>３．当社は、本サービスが登録利用者及びユーザの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、登録利用者及びユーザによる本サービスの利用が登録利用者及びユーザに適用のある法令または業界団体の内部規則等に適合すること、および不具合が生じないことについて、何ら保証するものではありません。</p>
            <p>４．当社は、本サービスが全ての情報端末に対応していることを保証するものではなく、本サービスの利用に供する情報端末のＯＳのバージョンアップ等に伴い、本サービスの動作に不具合が生じる可能性があることにつき、登録利用者はあらかじめ了承するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。</p>
            <p>５．当社は、本サービスを利用したことにより直接的または間接的にユーザに発生した損害について、一切賠償責任を負いません。</p>
            <p>６．当社は、ユーザその他の第三者に発生した機会逸失、業務の中断その他いかなる損害（間接損害や逸失利益を含みます）に対して、当社が係る損害の可能性を事前に通知されていたとしても、一切の責任を負いません。</p>
            <p>７．第１項乃至前項の規定は、当社に故意または重過失が存する場合には適用しません。</p>
            <p>８．本サービスの利用に関し当社が損害賠償責任を負う場合、当該損害が発生した月に登録利用者から受領した利用額を限度として賠償責任を負うものとします。</p>
            <p>９．ユーザと他のユーザとの間の紛争及びトラブルについて、当社は一切責任を負わないものとします。ユーザと他のユーザでトラブルになった場合でも、両者同士の責任で解決するものとし、当社には一切の請求をしないものとします。</p>
            <p>１０．ユーザは、本サービスの利用に関連し、他のユーザに損害を与えた場合または第三者との間に紛争を生じた場合、自己の費用と責任において、かかる損害を賠償またはかかる紛争を解決するものとし、当社には一切の迷惑や損害を与えないものとします。</p>
            <p>１１．ユーザの行為により、第三者から当社が損害賠償等の請求をされた場合には、ユーザの費用（弁護士費用）と責任で、これを解決するものとします。当社が、当該第三者に対して、損害賠償金を支払った場合には、ユーザは、当社に対して当該損害賠償金を含む一切の費用（弁護士費用及び逸失利益を含む）を支払うものとします。</p>
            <p>１２．ユーザが本サービスの利用に関連して当社に損害を与えた場合、ユーザの費用と責任において当社に対して損害を賠償（訴訟費用及び弁護士費用を含む）するものとします。</p>
            <br>
            <h1>第１０条（本サービスの停止）</h1>
            <br>
            <p>１．当社は、以下のいずれかに該当する場合には、登録利用者に事前に通知することなく、本サービスの利用の全部又は一部を停止又は中断することができるものとします。</p>
            <p class="indent2">（１）本サービスに係るコンピューター・システムの点検、保守作業、本サービスのバージョンアップのための作業を定期的又は緊急に行う場合</p>
            <p class="indent2">（２）コンピューター、通信回線等が事故により停止した場合</p>
            <p class="indent2">（３）火災、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合</p>
            <p class="indent2">（４）外部サービスに、トラブル、サービス提供の中断又は停止、本サービスとの連携の停止、仕様変更等が生じた場合</p>
            <p class="indent2">（５）電気通信事業者が電気通信サービスの提供を中止するなど、当社以外の第三者の行為に起因して、本サービスの提供を行うことが困難になった場合</p>
            <p class="indent2">（６）その他、当社が停止又は中断を必要と判断した場合</p>
            <p>２．当社は、本条に基づき当社が行った措置によって登録利用者及びユーザに生じた損害について一切の責任を負いません。</p>
            <br>
            <h1>第１１条（当社による本サービスの利用停止又は利用契約の解除）</h1>
            <br>
            <p>１．当社は、登録利用者が以下の各号のいずれかの事由に該当する場合、事前の通知又は催告をすることなく、保存されているデータ（登録情報を含みますが、これに限られません。）の削除、本サービス利用の一時的な停止、登録の抹消又は利用契約の解除を行うことができるものとします。</p>
            <p class="indent2">（１）登録利用者又はユーザが本規約に違反する行為を行った場合</p>
            <p class="indent2">（２）登録情報の全部又は一部に虚偽、誤記又は記載漏れがあった場合</p>
            <p class="indent2">（３）当社からの問い合わせその他の回答を求める連絡に対して14日間以上応答がない場合</p>
            <p class="indent2">（４）支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合</p>
            <p class="indent2">（５）自ら振出し、若しくは引受けた手形若しくは小切手につき、不渡りの処分を受けた場合、又は手形交換所の取引停止処分その他これに類する措置を受けたとき</p>
            <p class="indent2">（６）差押、仮差押、仮処分、強制執行又は競売の申立てがあった場合</p>
            <p class="indent2">（７）過去に本サービスについて退会処分を受けたことが判明した場合</p>
            <p class="indent2">（８）その他、当社が登録利用者の本サービスの利用、登録又は利用契約の継続を適当でないと判断した場合</p>
            <p>２．前項各号のいずれかの事由に該当した場合、登録利用者は、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払いを行なわなければなりません。</p>
            <p>３．当社は、本条に基づき当社が行った行為によりユーザに生じた損害について一切の責任を負いません。</p>
            <br>
            <h1>第１２条（解約）</h1>
            <br>
            <p>１．登録利用者は、利用契約の有効期間中であっても、希望する利用契約解約日の１か月前までに当社所定の方法で当社に通知することにより、利用契約を解約することができます。</p>
            <p>２．解約にあたり登録利用者が当社に対して債務を負っている場合、登録利用者は、当社に対する債務の一切について当然に期限の利益を失い、直ちに当社に対する債務の全てについて履行しなければなりません。</p>
            <p>３．登録利用者は、第１項に基づき利用契約を解約する場合であっても、解約日から利用契約満了日までに発生する予定であった利用料金相当額を当社に対し支払わなければなりません。</p>
            <br>
            <h1>第１３条（広告の掲載について）</h1>
            <br>
            <p class="notIndent">　登録利用者は、本サービス上にあらゆる広告が含まれる場合があること、当社またはその提携先があらゆる広告を掲載する場合があることを理解しこれを承諾したものとみなします。本サービス上の広告の形態や範囲は、当社によって随時変更されます。</p>
            <br>
            <h1>第１４条（権利譲渡の禁止）</h1>
            <br>
            <p class="notIndent">　登録利用者は、予め当社の書面による承諾がない限り、本規約上の地位および本規約に基づく権利または義務の全部または一部を第三者に譲渡してはならないものとします。</p>
            <br>
            <h1>第１５条（権利帰属）</h1>
            <br>
            <p class="notIndent">　当社ウェブサイトおよび本サービスに関する所有権及び知的財産権は全て当社又は当社にライセンスを許諾しているものに帰属しており、本規約に定める登録に基づく本サービスの利用許諾は、当社ウェブサイトまたは本サービスに関する当社又は当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。</p>
            <br>
            <h1>第１６条（反社会的勢力の排除）</h1>
            <br>
            <p>１．ユーザ及び当社は、相手方に対し、自己又は自己の役員若しくは自己の従業員が、現時点において、暴力団、暴力団員、暴力団員でなくなったときから５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動標榜ゴロ又は特殊知能暴力集団等、その他これらに準ずる者（以下これらを「暴力団員等」といいます。）に該当しないこと及び各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約するものとします。</p>
            <p class="indent2">（１）暴力団員等が経営を支配している又は経営に実質的に関与していると認められる関係を有すること</p>
            <p class="indent2">（２）自己又は第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってする等、不当に暴力団員等を利用していると認められる関係を有すること</p>
            <p class="indent2">（３）暴力団員等に対して資金等を提供し、又は便宜を供与する等の関係を有すること</p>
            <p class="indent2">（４）役員又は経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること</p>
            <p>２．ユーザ及び当社は、前項の表明及び確約に反して、相手方又は相手方の役員若しくは相手方の従業員が暴力団員等又は前項の各号のいずれかに該当することが判明したときは、何らの催告をせず、利用契約を解除することができるものとします。</p>
            <p>３．第１項各号のいずれかの事由に該当した場合、ユーザは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払いを行なわなければなりません。</p>
            <p>４．当社は、第２項に基づき当社が行った利用契約の解除によりユーザに生じた損害について一切の責任を負いません。</p>
            <br>
            <h1>第１７条（秘密保持および個人情報の保護）</h1>
            <br>
            <p>１．当社は、登録利用者が利用料金の支払いの遅滞、第三者への損害の発生、不正・不当な目的でのサービスの利用など、本サービスや当社または事業者の提供する他のサービスにおいて、当該サービスに関する利用規約またはガイドライン等に違反した場合、以後の各サービスの利用をお断りするため、事業者に対して違反者を特定するための情報を提供することがあります。</p>
            <p>２．当社は、本規約に別段の定めがある場合を除き、本サービスの提供に関連して知り得た登録情報を当社が別に定める「プライバシーポリシー」に基づき取り扱うものとします。</p>
            <br>
            <h1>第１８条（分離可能性）</h1>
            <br>
            <p class="notIndent">　本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。</p>
            <br>
            <h1>第１９条（当社への連絡方法）</h1>
            <br>
            <p class="notIndent">　本サービスに関するユーザの当社へのご連絡・お問い合わせは、下記メールアドレス宛に行うものとします。</p>
            <p class="centerAlign">お問合わせ先：prooface_info@cotofure.com</p>
            <br>
            <h1>第２０条（管轄裁判所）</h1>
            <br>
            <p class="notIndent">　当社と登録利用者との間での論議・訴訟その他一切の紛争については、訴額に応じて、東京簡易裁判所又は東京地方裁判所を専属的合意管轄裁判所とします｡</p>
            <br>
            <h1>第２１条（準拠法）</h1>
            <br>
            <p class="notIndent">　本規約の有効性、解釈及び履行については、日本法に準拠し、日本法に従って解釈されるものとします。</p>
            <br>
            <h1>第２２条（協議解決）</h1>
            <br>
            <p class="notIndent">　当社及びユーザは、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。</p>
            <p class="rightAlign">２０２４年１月１５日　施行</p>
        </div>
        <div class="marginArea"></div>
        <div class="agreeTermArea">
            <p class="rightAlign">別紙</p>
            <p id="title">登録情報その他の個人情報の利用目的について</p>
        </div>
        <div class="agreeTerm">
            <br>
            <p class="notIndent">　当社は、本サービスの提供に伴い登録利用者から取得する登録情報及びその他の個人情報を以下の目的で利用するものとします。</p>
            <br>
            <p>１．本サービスの提供・運営のため</p>
            <p>２．本サービスのパフォーマンス向上を図るため</p>
            <p>３．登録利用者からのお問い合わせに回答するため（本人確認を行うことを含む）</p>
            <p>４．登録利用者が利用中の本サービスの新機能、更新情報、キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するため</p>
            <p>５．メンテナンス、重要なお知らせなど必要に応じたご連絡のため</p>
            <p>６．利用規約に違反したユーザや、不正・不当な目的でサービスを利用しようとするユーザの特定をし、ご利用をお断りするため</p>
            <p>７．有料サービスにおいて、登録利用者に利用料金を請求するため</p>
            <p>８．上記の利用目的に付随する目的</p>
            <button id="agreeButton" @click="agreeTerm">利用規約に同意する</button>
        </div>
    </main>
</template>

<style scoped>
    #title {
        font-size: 150%;
        margin: 0 0 50px 0;
    }
    .agreeTermArea {
        margin: 0 15% 0 15%;
    }
    .agreeTerm {
        margin: 0 15% 0 15%;
    }
    .agreeTerm h1 {
        text-align: left;
        text-indent: -2em;
    }
    .agreeTerm p {
        text-align: left;
        list-style: none;
        text-indent: -2em;
        padding-left: 2em;

    }
    .agreeTerm .notIndent {
        text-align: left;
        text-indent: -0em;
        padding-left: 0em;
    }
    .agreeTerm .indent2 {
        text-indent: -3em;
        padding-left: 5em;
        text-align: left;
    }
    .agreeTerm .indent3 {
        text-indent: -4em;
        padding-left: 3em;
    }
    .agreeTermArea .rightAlign, .agreeTerm .rightAlign {
        text-align: right;
    }
    .agreeTerm .centerAlign {
        text-align: center;
    }
    #agreeButton {
        margin: 50px 0 50px 0;
        border: solid 3px #7CD5F5;
        background: #7CD5F5;
    }
    .marginArea {
        padding: 40px 0 40px 0;
    }
</style>
