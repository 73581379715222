<script setup>
    import { computed, reactive, vShow, watch } from 'vue'

    //親コンポーネントから受け継いだ端末情報
    const props = defineProps({
        terminalObject: {
            type: Object,
            require: true
        },
        NightFlag: {
            type: Boolean,
            require: true
        }
    });
    const emit = defineEmits(['executeMethod']);
    //モーダルウィンドウが閉じるときに実行
    const exitModal = (editFlag, mode) => {
        if(editFlag) {
            emit('executeMethod', true, mode);
        }
        else {
            emit('executeMethod', false);
        }
    }
</script>

<template>
    <div class="modalArea">
        <div id="modalContent" class="modal">
            <p class="modalTitle">端末の操作</p>
            <div class="buttonArea">
                <button class="commonButtonEdit" @click="exitModal(true, 'Open')" v-show="!props.terminalObject.CompletelyLock">開ける</button>
                <button class="commonButtonEdit" @click="exitModal(true, 'Close')" v-show="!props.terminalObject.CompletelyLock">閉じる</button>
                <button class="commonButtonEdit" @click="exitModal(true, 'Lock')" v-show="!props.terminalObject.CompletelyLock && !props.NightFlag">単体施錠</button>
                <button class="commonButtonEdit" @click="exitModal(true, 'Open')" v-show="props.terminalObject.CompletelyLock">開ける</button>
                <button class="commonButtonEdit" @click="exitModal(false)">キャンセル</button>
                <p v-show="props.terminalObject.CompletelyLock">※ 開ける操作を行うと、<br>単体施錠は解除されます<br></p>
            </div>
        </div>
        <div class="modalOverlay" @click.self="exitModal(false)"></div>
    </div>
</template>

<style scoped>
    .modal {
        width: 300px;
        height: auto;
        top: 50%;
        left: 50%;
    }
    .buttonArea {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .commonButtonEdit {
        width: 90%;
        margin: 2% 0 2% 0;
    }
    @media (max-width: 1150px) {
        .modal {
            width: 300px;
            height: auto;
            display: flex;
            justify-content: left;
            align-items: left;
            overflow-y: scroll;
            overflow-x: hidden;
        }
        .modal p {
            padding: 10px 0 10px 0;
        }
        .commonButtonEdit {
            margin: 20px 0 20px 0;
            top: 30px;
            height: auto;
        }
    }
</style>