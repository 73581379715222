<script setup>
    import { onMounted, onBeforeUnmount } from 'vue'
    import HeaderComponent from '../../components/common/HeaderComponent.vue'
    import NavigationBar from '../../components/business/NavigationBar.vue'

    //iframeの設定
    const handleResize = () => {
        //iframeにて埋め込まれた要素を取得
        const bownow = document.getElementById('_bownow_iframe_sid_e918e9960bf59aad74e5')
        //メディアクエリ
        const mediaQuery = window.matchMedia('(max-width: 1150px)')
        //画面サイズに応じて横幅と横の位置を変更する
        bownow.style.width = !mediaQuery.matches ? "70vw" : "90vw";
        bownow.style.left = !mediaQuery.matches ? "25vw" : "5vw";
        bownow.style.height = "80vh"
        bownow.style.top = "120px"
        bownow.style.display = "inline"
        bownow.style.position = "absolute"
    }
    ////コンポーネントがマウントされた後に実行
    onMounted(() => {
        //イベントリスナーの追加（画面サイズ変更時）
        window.addEventListener('resize', handleResize)
        //iframeの表示
        handleResize()
    })
    //コンポーネントがアンマウントされる前に実行
    onBeforeUnmount(() =>{
        const bownow = document.getElementById('_bownow_iframe_sid_e918e9960bf59aad74e5')
        //大枠のBody直下にiframeが存在しているのでその他のページにある時は非表示とさせる
        bownow.style.display = "none"
        //イベントリスナーの削除
        window.removeEventListener('resize', handleResize)
    })
</script>

<template>
    <HeaderComponent></HeaderComponent>
    <NavigationBar></NavigationBar>
    <main>
        <div class="requestPlan">
            <div class="planArea">
                <p class="title">プラン変更・退会申請</p>
            </div>
            <div id="planArea">
            </div>
        </div>
    </main>
</template>

<style scoped>
    .requestPlan {
        padding: 0 5% 0 25%;
        background-color: #e7e6e6;
    }
    @media (max-width: 1150px) {
        .requestPlan {
            padding: 0 5% 0 5%;
        }
    }
</style>
