<script setup>
    import { watch, reactive } from 'vue'

    const emit = defineEmits(['executeMethod']);
    const reactiveObj = reactive({
        //ドア（端末名）
        TerminalName: '',
        //設置先
        Location: '',
        //親機/子機/単独
        TerminalType: '',
        //施錠までの時間
        LockTime: '',
    })
    //リアクティブオブジェクトのリセット
    const valueReset = () => {
        reactiveObj.TerminalName = ''
        reactiveObj.Location = ''
        reactiveObj.TerminalType = ''
        reactiveObj.LockTime = ''
    }
    //モーダルウィンドウが閉じるときに実行
    const exitModal = (editFlag) => {
        //親コンポーネントに編集の是非とその値を渡す
        emit('executeMethod', editFlag, reactiveObj);
        //モーダルウィンドウを閉じる際に追加ボタンを非活性化
        document.getElementById('commonButtonAdd').disabled = true;
        valueReset()
    }
    //リアクティブオブジェクトの監視
    watch(reactiveObj, () => {
        //術の値が入力された場合に追加ボタンを活性化
        if(reactiveObj.TerminalName && reactiveObj.Location && reactiveObj.TerminalType && reactiveObj.LockTime){
            document.getElementById('commonButtonAdd').disabled = false;
        }
    })
</script>

<template>
    <div class="modalArea">
        <div id="modalContent" class="modal">
            <p class="modalTitle">端末情報の追加</p>
            <div class="modalInfo">
                <p>端末名</p>
                <input class="modalTerminal" v-model="reactiveObj.TerminalName">
            </div>
            <div class="modalInfo">
                <p>設置先</p>
                <select class="modalLocation" v-model="reactiveObj.Location">
                    <option value="外側">外側</option>
                    <option value="内側">内側</option>
                </select>
            </div>
            <div class="modalInfo">
                <p>親子設定</p>
                <select class="modalTerminalType" v-model="reactiveObj.TerminalType">
                    <option value="parent">親機</option>
                    <option value="single">単独</option>
                </select>
            </div>
            <div class="modalInfo">
                <p>施錠までの時間</p>
                <select class="modalLocktime" v-model="reactiveObj.LockTime">
                    <option value="5">5秒</option>
                    <option value="7">7秒</option>
                    <option value="10">10秒</option>
                    <option value="15">15秒</option>
                    <option value="20">20秒</option>
                    <option value="30">30秒</option>
                    <option value="0">自動で施錠しない</option>
                    <option value="-1">オープンセンサーを使用</option>
                </select>
            </div>
            <button id="commonButtonAdd" @click="exitModal(true)" disabled>追加する</button>
        </div>
        <div class="modalOverlay" @click.self="exitModal(false)"></div>
    </div>
</template>

<style scoped>
    .modal {
        width: 700px;
        height: 500px;
        top: 50%;
        left: 50%;
    }
    #commonButtonAdd {
        width: 190px;
        height: 70px;
        top: 12%;
        left: 50%;
    }
    @media (max-width: 1150px) {
        .modal {
            width: 85%;
            height: auto;
            display: flex;
            justify-content: left;
            align-items: left;
            overflow-y: scroll;
            overflow-x: hidden;
        }
        .modal p {
            padding: 10px 0 10px 0;
        }
        .modalInfo {
            flex-direction: column;
            padding: 3% 0 3% 0;
            position: relative;
        }
        .modalInfo p {
            position: relative;
            top: 0%;
            width: 100%;
            font-size: 16px;
        }
        #commonButtonAdd {
            margin: 20px 0 20px 0;
            top: 30px;
            height: auto;
        }
    }
</style>