<script setup>
    import { onMounted, reactive } from 'vue'

    //親コンポーネントから受け継いだ値を格納するオブジェクト
    const props = defineProps({
        modalMessage: {
            type: String,
            require: true
        },
        modalRedMessage: {
            type: String,
            require: true
        },
        otherObject: {
            type: Object,
            require: true
        },
    });
    //デフォルトのモーダルウィンドウの為、選択肢が1つかそうでないかを判別枢要のリアクティブオブジェクト
    const reactiveModalObj = reactive({
        choiceFlag: false,
    })
    const emit = defineEmits(['executeMethod']);
    const exitModal = (editFlag) => {
        emit('executeMethod', editFlag);
    }
    //コンポーネント生成時に実行
    onMounted(() => {
        //モーダルウィンドウに表示するメッセージがあれば埋め込む
        if(props.modalMessage) {
            document.getElementById('checkMessage').innerHTML = props.modalMessage
        }
        //モーダルウィンドウに表示する注意文（赤文字）があれば埋め込む
        if(props.modalRedMessage) {
            document.getElementById('checkRedMessage').innerHTML = props.modalRedMessage
        }
        try {
            //選択肢が2つ以上の場合に肯定するボタンのメッセージを埋め込む
            if(props.otherObject.confirm) {
                document.getElementById('commonButtonTrue').innerHTML = props.otherObject.confirm
                reactiveModalObj.choiceFlag = true
            }
            //選択肢が1つしか親コンポーネントから受け取っていない場合にはキャンセルボタンを排除する
            else {
                let element = document.getElementById('commonButtonFalse');
                element.remove();
                reactiveModalObj.choiceFlag = false
            }
        }
        catch {}
    })
</script>

<template>
    <div class="modalArea">
        <div id="modalContent" class="modal">
            <p id="checkMessage"></p>
            <p id="checkRedMessage"></p>
            <p></p>
            <div class="buttons">
                <button id="commonButtonTrue" @click="exitModal(true)">はい</button>
                <span class="buttonBetween" v-show="reactiveModalObj.choiceFlag"></span>
                <button id="commonButtonFalse" @click="exitModal(false)" v-show="otherObject">キャンセル</button>
            </div>
        </div>
        <div class="modalOverlay" @click.self="exitModal(false)"></div>
    </div>
</template>

<style scoped>
    .modal {
        width: 30%;
        height: auto;
        top: 50%;
        left: 50%;
        font-size: 140%;
    }
    #checkMessage {
        padding: 30px 0 30px 0;
        font-size: 100%;
        color: black;
    }
    #checkRedMessage {
        color: rgb(255, 0, 0);
        padding: 10px 10px 10px 10px;
        font-size: 100%;
    }
    .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .buttonBetween {
        padding: 0 5% 0 5%;
    }
    #commonButtonTrue {
        width: 150px;
        height: 60px;
        border-color: #7CD5F5;
        background: #7CD5F5;
    }
    #commonButtonFalse {
        width: 150px;
        height: 60px;
        border-color: #eb8f8f;
        background: #eb8f8f;
    }
    button[id^="commonButton"] {
        left: 0%;
        transform: translate(0%, 0%);
    }
    @media (max-width: 1150px) {
        .modal {
            width: 90%;
            height: auto;
            top: 50%;
            left: 50%;
            font-size: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
        }
        #commonButtonTrue {
            width: 120px;
            height: auto;
            font-size: 14px;
            border: solid 3px #7CD5F5;
            background: #7CD5F5;
        }
        #commonButtonFalse {
            width: 120px;
            height: auto;
            font-size: 14px;
            border: solid 3px #eb8f8f;
            background: #eb8f8f;
        }
    }
</style>