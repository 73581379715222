<script setup>
    import { computed, reactive, onMounted } from 'vue'
    import {formatToTimeZone} from 'date-fns-timezone';
    import {onAuthStateChanged } from "firebase/auth";
    import { ref as databaseRef, onValue, query, startAt, endAt, orderByChild } from "firebase/database";
    import { useRoute } from 'vue-router'
    import HeaderComponent from '../../components/common/HeaderComponent.vue'
    import NavigationBar from '../../components/business/NavigationBar.vue'
    import DefaultModalComponent from '../../components/common/DefaultModalComponent.vue'
    import PaginateComponent from 'vuejs-paginate-next'
    import Firebase from "../../firebase_settings/index.js"
    import Error from "../../firebase_settings/error.js"

    const DATEFORMAT1 = 'YYYY/MM/DD HH:mm:ss';
    const DATEFORMAT2 = 'YYYYMMDD';
    const DATEFORMAT3 = 'YYYY/MM/DD';
    const TIME_ZONE_TOKYO = 'Asia/Tokyo';
    const auth = Firebase.auth
    const database = Firebase.database;
    const route = useRoute()

    const reactiveObj = reactive({
        //企業ID
        corporateId: "",
        //ログ一覧
        logs: [],
        //検索用のログ一覧
        searchLogs: [],
        //再レンダリング用のキー
        renderKey: 0,
        //現在のページ（デフォルトは１ページ）
        currentPage: 1,
        //検索開始日
        fromDate: 0,
        //検索終了日
        toDate: 0,
        //姓名フラグ
        UseNameFlag1: false,
        //セイメイフラグ
        UseNameFlag2: false,
    })
    const reactiveModalObj = reactive({
        //モーダル開閉
        modalFlag: false,
        //モーダルに表示するメッセージ
        modalMessage: "",
        //モーダルの再描画用のキー
        modalRenderkey: 0,
    })
    //1ページに表示する件数
    const perPage = 10;

    //firebaseからログ一覧を取得
    const getLogs = () => {
        //同期処理
        return new Promise(async (resolve, reject) => {
            //企業コードが読み込めている場合
            if(reactiveObj.corporateId) {
                //検索などで取得するログデータが変わる為、実行されるたびに一度中身をリセットさせる
                reactiveObj.searchLogs = [];
                //現状は固定
                const userGroup = 'All'
                const logsRef = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/UnlockHistory/${userGroup}`);
                //件数が多くなる野が想定されるので事前にフィルタリング
                const logsRefQuery = query(logsRef, orderByChild('LatestHistory'), startAt(reactiveObj.fromDate), endAt(reactiveObj.toDate))
                //firebaseからログを取得する
                const unsubscribe = onValue(logsRefQuery, async (snapshot) => {
                    const logData = (snapshot.val());
                    for(let key in logData) {
                        reactiveObj.searchLogs.push({
                            logGroup: logData,
                            Id: logData[key]['Id'],
                            LatestHistory: logData[key]['LatestHistory'],
                            UserName1: logData[key]['UserName1'],
                            UserName2: logData[key]['UserName2'],
                            TerminalName: logData[key]['TerminalName'],
                            EntryExit: logData[key]['EntryExit'],
                            CodeType: (
                                logData[key]['CodeType'] == 0 ? '顔認証' :
                                    logData[key]['CodeType'] == 1 ? 'コード認証' :
                                        logData[key]['CodeType'] == 2 ? '遠隔解錠' :
                                            logData[key]['CodeType'] == 3 ? 'セサミタッチ認証' :
                                                logData[key]['CodeType'] == 4 ? '指紋認証' :
                                                    logData[key]['CodeType'] == 5 ? 'Felica認証' : 'その他'
                            ),
                        })
                    }

                    //LatestHistory順に並べ替え
                    reactiveObj.searchLogs = await reactiveObj.searchLogs.sort((element, nextElement) => {
                        return (element['LatestHistory'] > nextElement['LatestHistory']) ? -1 : 1;
                    })
                    //破棄
                    unsubscribe()
                    return resolve("")
                });
            } else {
                return reject("")
            }
        });
    }
    //ログ検索時に実行する
    const searchLog = async() => {
        //Fromの値を00:00:00.000000に調整(UNIX)
        reactiveObj.fromDate = new Date(document.getElementById('fromDate').value).getTime() - 32400000
        //Toの値を23:59:59:999999に調整(UNIX)
        reactiveObj.toDate = new Date(document.getElementById('toDate').value).getTime() + 53999999
        //以下の条件でTrueとなる
        //１．Fromが入力されている
        //２．Toが入力されている
        if(!reactiveObj.fromDate || !reactiveObj.toDate) {
            alert(Error.errorResult({ code: 'ELE0001'}, route.name))
            return
        }
        //FromがToより未来にある場合
        else if(reactiveObj.fromDate > reactiveObj.toDate) {
            alert(Error.errorResult({ code: 'ELE0002'}, route.name))
            return
        }
        //同期処理にてログを取得する
        await getLogs()
        //エラー時
        .catch(() => {
            alert(Error.errorResult({ code: 'ELE0003'}, route.name))
        })
    }
    //ページネーションを押下した時
    const clickCallback = (pageNum) => {
        reactiveObj.currentPage = Number(pageNum);
    }
    //Nページ目に表示するドア情報を取得
    const getItems = computed(() => {
        let current = reactiveObj.currentPage * perPage;
        let start = current - perPage;
        return reactiveObj.searchLogs.slice(start, current);
    })
    //現在のページ数を取得
    const getPageCount = computed(() => {
        return Math.ceil(reactiveObj.searchLogs.length / perPage) ? Math.ceil(reactiveObj.searchLogs.length / perPage) : 1
    })
    //モーダルウィンドウを開く際に実行
    const showDefaultModal = () => {
        //検索時に条件に合致する件数が０件の時
        if(reactiveObj.searchLogs.length == 0){
            alert(Error.errorResult({ code: 'ELE0004'}, route.name))
            return
        }
        //検索時に条件に合致する件数が１件以上の時
        else {
            //メッセージ表示用にFrom、ToをJSTに変換（YYYY/MM/DD）
            const searchFrom = formatToTimeZone(reactiveObj.fromDate, DATEFORMAT3, { timeZone: TIME_ZONE_TOKYO })
            const searchTo = formatToTimeZone(reactiveObj.toDate, DATEFORMAT3, { timeZone: TIME_ZONE_TOKYO })
            //モーダルを表示
            reactiveModalObj.modalFlag= true
            //モーダル上の表示する文面
            reactiveModalObj.modalMessage = `${searchFrom}～${searchTo}<br>のログを出力します。<br><br>よろしいですか？`
            //モーダル部分を再描画
            reactiveModalObj.modalRenderkey++
        }
    }
    //モーダルウィンドウを閉じる際に実行
    const closeDefaultModal = (editFlag) => {
        //モーダルウィンドウを閉じる
        reactiveModalObj.modalFlag= false
        if(editFlag) {
            //ファイル名用にFrom、ToをJSTに変換（YYYYMMDD）
            const nameFrom = formatToTimeZone(reactiveObj.fromDate, DATEFORMAT2, { timeZone: TIME_ZONE_TOKYO })
            const nameTo = formatToTimeZone(reactiveObj.toDate, DATEFORMAT2, { timeZone: TIME_ZONE_TOKYO })
            let csvContent = '\ufeff' + `日時,ID${reactiveObj.UseNameFlag1 ? ",利用者" : ""}${reactiveObj.UseNameFlag2 ? ",利用者(セイメイ)" : ""},端末名,入退室,解錠種別\n`
            //CSVデータの作成
            reactiveObj.searchLogs.forEach(logsElement => {
                let date = formatToTimeZone(logsElement['LatestHistory'], DATEFORMAT1, { timeZone: TIME_ZONE_TOKYO })
                let UserName1 = reactiveObj.UseNameFlag1 ? `,${logsElement['UserName1']}` : ""
                let UserName2 = reactiveObj.UseNameFlag2 ? `,${logsElement['UserName2']}` : ""
                let line = `${date},${logsElement['Id']}${UserName1}${UserName2},${logsElement['TerminalName']},${logsElement['EntryExit']},${logsElement['CodeType']}\n`
                csvContent += line
            })
            let blob = new Blob([csvContent], { type: 'text/csv' })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${nameFrom}-${nameTo}_logfile.csv`
            link.click()
        }
    }
    //企業コードの取得
    const getCorporateId = () => {
        //同期処理
        return new Promise((resolve) => {
            //ログインチェック
            onAuthStateChanged(auth, (user) => {
                const getData = databaseRef(database, `LoginUser/${user.uid}`);
                onValue(getData, (snapshot) => {
                    resolve(snapshot.val()["CorporateId"] ? snapshot.val()["CorporateId"] : "");
                })
            })
		})
    }
    //アプリ設定情報の取得
    const getAppsetting = () => {
        const settingsRef = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/AppSettings`)
            //firebaseからアプリ情報を取得する
            onValue(settingsRef, (snapshot) => {
                const settings = (snapshot.val())
                reactiveObj.UseNameFlag1 = settings["UseName1"]
                reactiveObj.UseNameFlag2 = settings["UseName2"]
            })
    }
    //コンポーネントがマウントされた後に実行
    onMounted(async () => {
        //企業コードを取得
        reactiveObj.corporateId = await getCorporateId()
        //アプリ設定を取得
        await getAppsetting()
    })
</script>

<template>
    <HeaderComponent></HeaderComponent>
    <NavigationBar></NavigationBar>
    <main>
        <div class="entryExitLogs" :key="`${reactiveObj.renderKey}-entryExitLogs`">
            <div class="titleArea">
                <p class="title">入退室ログ</p>
            </div>
            <div class="entryExit">
                <p>期間指定</p>
                <div class="entryContents">
                    <input type="date" id="fromDate">
                    <p>～</p>
                    <input type="date" id="toDate">
                    <button id="searchButton1" @click="searchLog()">検索</button>
                </div>
                <button id="searchButton2" @click="searchLog()">検索</button>
            </div>
            <div class="tableArea">
                <table class="commonTable">
                    <tbody>
                        <tr>
                            <th class="leftSideTh">日時</th>
                            <th>ID</th>
                            <th v-if="reactiveObj.UseNameFlag1">利用者</th>
                            <th v-if="reactiveObj.UseNameFlag2">利用者(フリガナ)</th>
                            <th>端末名</th>
                            <th>入退室</th>
                            <th>解錠種別</th>
                        </tr>
                        <tr v-for="(data, key) in getItems" :key="key">
                            <td class="leftSideTh">{{ formatToTimeZone(data.LatestHistory, DATEFORMAT1, { timeZone: TIME_ZONE_TOKYO }) }}</td>
                            <td>{{ data.Id }}</td>
                            <td v-if="reactiveObj.UseNameFlag1">{{ data.UserName1 }}</td>
                            <td v-if="reactiveObj.UseNameFlag2">{{ data.UserName2 }}</td>
                            <td>{{ data.TerminalName }}</td>
                            <td>{{ data.EntryExit }}</td>
                            <td>{{ data.CodeType }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="paginationArea">
                <!--
                <PaginateComponent
                    :page-count="getPageCount"
                    :click-handler="clickCallback"
                    :page-range="5"
                    :prev-text="reactiveObj.currentPage >= 2 ? '<' : ''"
                    :next-text="reactiveObj.currentPage < parseInt(reactiveObj.searchLogs.length / 10) + 1 ? '>' : ''"
                    :container-class="'pagination'"
                    :page-class="'page-item'">
                </PaginateComponent>
                -->
                <PaginateComponent
                    :page-count="getPageCount"
                    :click-handler="clickCallback"
                    :page-range="5"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :container-class="'pagination'"
                    :page-class="'page-item'">
                </PaginateComponent>
                <div class="downloadArea">
                    <p>CSVダウンロード　</p>
                    <button id="csvDownload" @click="showDefaultModal()">ダウンロード</button>
                </div>
            </div>
            <DefaultModalComponent :key="reactiveModalObj.modalRenderkey" :modalMessage="reactiveModalObj.modalMessage" v-show="reactiveModalObj.modalFlag" @executeMethod="closeDefaultModal"></DefaultModalComponent>
        </div>
    </main>
</template>

<style scoped>
    .entryExitLogs {
        padding: 0 5% 0 25%;
        background: #e7e6e6;
    }
    .entryExit {
        background-color: #F5F5F5;
    }
    .entryContents {
        display: flex;
        height: 100px;
        align-items: center;
        justify-content: space-around;
        gap: 5%;
        background-color: #F5F5F5;
        padding: 0 5% 0 5%;
    }
    #fromDate {
        width: 25%;
        border-radius: 10px;
        text-align: center;
        background-color: #EEEEEF;
    }
    #toDate {
        width: 25%;
        border-radius: 10px;
        text-align: center;
        background-color: #EEEEEF;
    }
    #searchButton1 {
        width: 25%;
        border: none;
        background-color: #7CD5F5;
    }
    #searchButton2 {
        display: none;
    }
    .tableArea {
        margin: 50px 0 0 0;
    }
    .downloadArea {
        display: flex;
        align-items: center;
        justify-content: right;
        padding: 0 10% 0 0;
    }
    .downloadArea #csvDownload {
        border: none;
        background-color: #7CD5F5;
    }
    @media (max-width: 1150px) {
        .entryExit {
            padding: 0 0 3% 0;
        }
        .entryExitLogs{
            padding: 0 5% 0 5%;
        }
        .entryContents #fromDate {
            width: 50%;
            border-radius: 10px;
            text-align: center;
            text-indent: 0em;
        }
        .entryContents #toDate {
            width: 50%;
            border-radius: 10px;
            text-align: center;
            margin: 3% 0 3% 0;
            text-indent: 0em;
        }
        #searchButton1 {
            display: none;
        }
        #searchButton2 {
            position: relative;
            display: block;
            width: 50%;
            left: 25%;
            border: none;
            background-color: #7CD5F5;
        }
        .tableArea {
            overflow-x: scroll;
        }
    }
</style>
